import {
    GET_OPTIMIZER_WORKLOAD,
    GET_OPTIMIZER_WORKLOAD_FAIL,
    GET_OPTIMIZER_WORKLOAD_SUCCESS,
} from './actionTypes';

export const getOptimizerWorkload = (date, limit = 5) => ({
    type: GET_OPTIMIZER_WORKLOAD,
    payload: { date, limit },
});

export const getOptimizerWorkloadSuccess = data => ({
    type: GET_OPTIMIZER_WORKLOAD_SUCCESS,
    payload: data,
});

export const getOptimizerWorkloadFail = error => ({
    type: GET_OPTIMIZER_WORKLOAD_FAIL,
    payload: error,
});
