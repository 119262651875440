/* LOCATION DETAIL */

export const GET_LOCATION_DETAIL = "GET_LOCATION_DETAIL"
export const GET_LOCATION_DETAIL_SUCCESS = "GET_LOCATION_DETAIL_SUCCESS"
export const GET_LOCATION_DETAIL_FAIL = "GET_LOCATION_DETAIL_FAIL"

/* GET LOCATION ACTIVE SHIPMENTS */

export const GET_LOCATION_SHIPMENTS_DETAIL = "GET_LOCATION_SHIPMENTS_DETAIL"
export const GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS = "GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS"
export const GET_LOCATION_SHIPMENTS_DETAIL_FAIL = "GET_LOCATION_SHIPMENTS_DETAIL_FAIL"


/* UPDATE LOCATION */

export const UPDATE_LOCATION = "UPDATE_LOCATION"
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS"
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL"

/* DELETE LOCATION */

export const DELETE_LOCATION = "DELETE_LOCATION"
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL"

