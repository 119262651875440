import {
    DELETE_LOCATION,
    DELETE_LOCATION_FAIL,
    DELETE_LOCATION_SUCCESS,
    GET_LOCATION_DETAIL,
    GET_LOCATION_DETAIL_FAIL,
    GET_LOCATION_DETAIL_SUCCESS,
    GET_LOCATION_SHIPMENTS_DETAIL,
    GET_LOCATION_SHIPMENTS_DETAIL_FAIL,
    GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS,
    UPDATE_LOCATION,
    UPDATE_LOCATION_FAIL,
    UPDATE_LOCATION_SUCCESS
} from "./actionTypes";


export const getLocationDetail = id => ({
    type: GET_LOCATION_DETAIL,
    payload: id,
})

export const getLocationDetailSuccess = locationDetail => ({
    type: GET_LOCATION_DETAIL_SUCCESS,
    payload: locationDetail,
})

export const getLocationDetailFail = error => ({
    type: GET_LOCATION_DETAIL_FAIL,
    payload: error,
}); 

export const getLocationShipmentsDetail = (id, skip, limit) => ({
    type: GET_LOCATION_SHIPMENTS_DETAIL,
    payload: { id, skip, limit },
});

export const getLocationShipmentsDetailSuccess = locationShipmentsDetail => ({
    type: GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS,
    payload: locationShipmentsDetail,
});

export const getLocationShipmentsDetailFail = error => ({
    type: GET_LOCATION_SHIPMENTS_DETAIL_FAIL,
    payload: error,
});

export const updateLocation = location => ({
    type: UPDATE_LOCATION,
    payload: location,
})

export const updateLocationSuccess = location => ({
    type: UPDATE_LOCATION_SUCCESS,
    payload: location,
})

export const updateLocationFail = error => ({
    type: UPDATE_LOCATION_FAIL,
    payload: error,
})

export const deleteLocation = id => ({
    type: DELETE_LOCATION,
    payload: id,
})

export const deleteLocationSuccess = () => ({
    type: DELETE_LOCATION_SUCCESS,
})

export const deleteLocationFail = error => ({
    type: DELETE_LOCATION_FAIL,
    payload: error,
})

