import {
  GET_ALL_LOCATIONS_FAIL,
  GET_ALL_LOCATIONS_SUCCESS,
  ADD_LOCATION_FAIL,
  ADD_LOCATION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  all: [],
  error: null,
  loading: true
}

const locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false,
      }
    case GET_ALL_LOCATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        all: state.all[0] !== "Empty" ? [...state.all, action.payload] : [action.payload],
        loading: false,
      }
    case ADD_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}

export default locations;