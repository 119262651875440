import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Locations
import locations from "./location/list/reducer";
import locationDetail from "./location/detail/reducer";

// Shipments
import shipments from "./shipment/list/reducer";
import shipmentDetail from "./shipment/detail/reducer";

// Vehicles
import vehicles from "./vehicle/list/reducer";

// Optimizer
import optimizer from "./optimizer/reducer";

// Dashboard
import dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  // Locations
  locations,
  locationDetail,

  // Shipments
  shipments,
  shipmentDetail,

  // Vehicles
  vehicles,

  // Dashboard
  optimizer,
  dashboard,
  
});

export default rootReducer;
