import {
    GET_OPTIMIZER_PAYLOAD,
    GET_OPTIMIZER_PAYLOAD_SUCCESS,
    GET_OPTIMIZER_PAYLOAD_FAIL,
    OPTIMIZE_ROUTE,
    OPTIMIZE_ROUTE_SUCCESS,
    OPTIMIZE_ROUTE_FAIL,
    UPLOAD_OPTIMIZER_TEMPLATE,
    UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS,
    UPLOAD_OPTIMIZER_TEMPLATE_FAIL,
    PLAN_OPTIMIZER_ROUTE,
    PLAN_OPTIMIZER_ROUTE_SUCCESS,
    PLAN_OPTIMIZER_ROUTE_FAIL,
    RESET_OPTIMIZER_DATA,

} from "./actionTypes";

export const getOptimizerPayload = date => ({
    type: GET_OPTIMIZER_PAYLOAD,
    payload: date
})

export const getOptimizerPayloadSuccess = data => ({
    type: GET_OPTIMIZER_PAYLOAD_SUCCESS,
    payload: data,
})

export const getOptimizerPayloadFail = error => ({
    type: GET_OPTIMIZER_PAYLOAD_FAIL,
    payload: error,
})

export const optimizeRoute = data => ({
    type: OPTIMIZE_ROUTE,
    payload: data
})

export const optimizeRouteSuccess = data => ({
    type: OPTIMIZE_ROUTE_SUCCESS,
    payload: data,
})

export const optimizeRouteFail = error => ({
    type: OPTIMIZE_ROUTE_FAIL,
    payload: error,
})

export const uploadOptimizerTemplate = data => ({
    type: UPLOAD_OPTIMIZER_TEMPLATE,
    payload: data
})

export const uploadOptimizerTemplateSuccess = data => ({
    type: UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS,
    payload: data,
})

export const uploadOptimizerTemplateFail = error => ({
    type: UPLOAD_OPTIMIZER_TEMPLATE_FAIL,
    payload: error,
})

export const planOptimizerRoute = (date, scenarioId) => ({
    type: PLAN_OPTIMIZER_ROUTE,
    payload: { date, scenarioId }
})

export const planOptimizerRouteSuccess = data => ({
    type: PLAN_OPTIMIZER_ROUTE_SUCCESS,
    payload: data,
})

export const planOptimizerRouteFail = error => ({
    type: PLAN_OPTIMIZER_ROUTE_FAIL,
    payload: error,
})

export const resetOptimizerData = () => ({
    type: RESET_OPTIMIZER_DATA,
})


