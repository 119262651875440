import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_LOCATION_DETAIL,
    GET_LOCATION_SHIPMENTS_DETAIL,
    DELETE_LOCATION,
    UPDATE_LOCATION
} from "./actionTypes";

import {
    updateLocationSuccess,
    updateLocationFail,
    deleteLocationSuccess,
    deleteLocationFail,
    getLocationDetailSuccess,
    getLocationDetailFail,
    getLocationShipmentsDetailSuccess,
    getLocationShipmentsDetailFail
} from "./actions";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../../helpers/jwt-token-access/accessToken";

import { getLocationDetail, getLocationShipmentsDetail, putLocation, delLocation } from "../../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchLocationDetail({ payload: id }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getLocationDetail, id)
        yield put(getLocationDetailSuccess(response))
    } catch (error) {
        yield put(getLocationDetailFail(error))
    }
}

function* fetchLocationShipmentsDetail({ payload: { id, skip, limit } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getLocationShipmentsDetail, id, skip, limit)
        if (response.length > 0) {
            yield put(getLocationShipmentsDetailSuccess(response))
        } else {
            yield put(getLocationShipmentsDetailSuccess(["Empty"]))
        }
    } catch (error) {
        yield put(getLocationShipmentsDetailFail(error))
    }
}

function* onUpdateLocation({ payload: location }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(putLocation, location)
        yield put(updateLocationSuccess(response))
        toast.success("Location Updated Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(updateLocationFail(error))
        toast.error("Location Updated Failed", { autoClose: 2000 });
    }
}

function* onDeleteLocation({ payload: id }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(delLocation, id)
        yield put(deleteLocationSuccess(response))
        toast.success("Location Deleted Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(deleteLocationFail(error))
        toast.error("Location Deleted Failed", { autoClose: 2000 });
    }
}

function* LocationDetailSaga() {
    yield takeEvery(GET_LOCATION_DETAIL, fetchLocationDetail)
    yield takeEvery(GET_LOCATION_SHIPMENTS_DETAIL, fetchLocationShipmentsDetail)
    yield takeEvery(UPDATE_LOCATION, onUpdateLocation)
    yield takeEvery(DELETE_LOCATION, onDeleteLocation)
}

export default LocationDetailSaga;