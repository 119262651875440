import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getAllLocations as onGetAllLocations,
  addLocation as onAddLocation,
} from "store/location/list/actions";
import { locationStatuses } from "constants/status";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

// Map
import { TileLayer, Marker, MapContainer, useMap } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";

import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import { searchReverseAddress, searchAddress } from "constants/map";
import { mapTileLayerUrl } from "constants/map";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const LocationsList = props => {

  //meta title
  document.title = "Flio.ai";

  const dispatch = useDispatch();
  const [location, setLocation] = useState();
  const [searchAddressModal, setSearchAddressModal] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [lat, setLat] = useState(41.015137);
  const [lng, setLng] = useState(28.979530);
  const mapRef = useRef(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (location && location.name) || "",
      latitude: (location && location.latitude) || "",
      longitude: (location && location.longitude) || "",
      shiftStart: (location && location.shiftStart) || "",
      shiftEnd: (location && location.shiftEnd) || "",
      address: (location && location.address) || "",
      city: (location && location.city) || "",
      state: (location && location.state) || "",
      zipCode: (location && location.zipCode) || "",
      country: (location && location.country) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Location-Name-Rq")),
      latitude: Yup.number().required(props.t("Location-Latitude-Rq")),
      longitude: Yup.number().required(props.t("Location-Longitude-Rq")),
      shiftStart: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, props.t("Invalid-Time")),
      shiftEnd: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, props.t("Invalid-Time")),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      country: Yup.string()
    }),
    onSubmit: values => {
      const newLocation = {
        name: values.name,
        latitude: values.latitude,
        longitude: values.longitude,
        shiftStart: values.shiftStart || null,
        shiftEnd: values.shiftEnd || null,
        address: values.address || null,
        city: values.city || null,
        state: values.state || null,
        zipCode: values.zipCode || null,
        country: values.country || null,
      };
      // save location
      dispatch(onAddLocation(newLocation));
      validation.resetForm();

      actionToggle();
    },
  });

  const selectLocationListState = state => state.locations;
  const LocationListProperties = createSelector(
    selectLocationListState,
    stateProperty => ({
      all: stateProperty.all,
      loading: stateProperty.loading,
      error: stateProperty.error,
    })
  );

  const { all, loading, error } = useSelector(LocationListProperties);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        Header: " ",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  <i className="fas fa-map-marker-alt" />
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.img}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Latitude"),
        accessor: "latitude",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Longitude"),
        accessor: "longitude",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Address"),
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          const addressData = cellProps.row.original;
          let addressStr = addressData.address ? addressData.address + ", " : '';
          addressStr += addressData.city ? addressData.city + ", " : '';
          addressStr += addressData.state ?? '';
          return addressStr ?? '-';
        },
      },
      {
        Header: props.t("Shift-Start"),
        accessor: "shiftStart",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Shift-End"),
        accessor: "shiftEnd",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: " ",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Badge className={locationStatuses[cellProps.value].style}>
            {locationStatuses[cellProps.value].text}
          </Badge>
        },
      },
      {
        Header: " ",
        accessor: "id",
        filterable: false,
        Cell: cellProps => {
          return (
            <Link className="btn btn-outline-primary waves-effect waves-light btn-sm"
              to={`/location/detail/${cellProps.value}`}
            >
              {props.t("Details")}
            </Link>)
        },
      },
    ],
    [props.router.location.pathname]
  );

  useEffect(() => {
    if (all && !all.length) {
      dispatch(onGetAllLocations());
    }
  }, [dispatch, all]);

  // useEffect(() => {
  //   if (props.router.location.pathname === "/location") {
  //     dispatch(onGetAllLocations());
  //   }
  // }, [props.router.location]);

  const mapIcon = new L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    html: renderToStaticMarkup(
      <div className='leaflet-pin' style={{ backgroundColor: "#57b6eb" }}>
        <span>{"●"}</span>
      </div>
    )
  });

  const MapComponent = () => {
    const map = useMap();
    var button = L.control({ position: 'topright' });

    useEffect(() => {
      button.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
        div.innerHTML = `
        <button style="width: 30px; height: 30px; border-radius: 4px; 
                        border: 2px solid rgba(0,0,0,0.2); background-color: #ffffff; 
                        display: flex; justify-content: center; 
                        align-items: center; font-size: 16px; font-weight: 600; cursor: pointer;">
          <i class="mdi mdi-map-search-outline font-size-18" style="color: #000000;"></i>
        </button>
      `;

        div.onclick = function (e) {
          e.preventDefault();
          e.stopPropagation();
          setSearchAddressModal(true);
        };
        return div;
      }
      button.addTo(map);
      return () => {
        map.removeControl(button);
      }
    }, [map]);
    return null;
  };

  const actionToggle = () => {
    setModal(!modal);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const searchReverse = async (lat, lng) => {
    validation.setFieldValue('latitude', lat.toFixed(6));
    validation.setFieldValue('longitude', lng.toFixed(6));

    const result = await searchReverseAddress([lat, lng]);
    validation.setFieldValue('address', result.address);
    validation.setFieldValue('city', result.city);
    validation.setFieldValue('state', result.state);
    validation.setFieldValue('zipCode', result.zipCode);
    validation.setFieldValue('country', result.country);
  }

  const search = async (address) => {
    // Clear the existing timeout if it's set
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Add a delay of 1000 milliseconds (1 second)
    const delay = 1000;

    // Set a new timeout and update the searchTimeout state
    const timeoutId = setTimeout(async () => {
      const result = await searchAddress(address);
      if (!result.length) {
        setAddressNotFound(true);
      } else {
        setAddressNotFound(false);
      }
      setAddresses(result);
    }, delay);

    setSearchTimeout(timeoutId);
  };

  const setAddress = (address) => {
    validation.setFieldValue('address', address.address);
    validation.setFieldValue('city', address.city);
    validation.setFieldValue('state', address.state);
    validation.setFieldValue('zipCode', address.zipCode);
    validation.setFieldValue('country', address.country);
    validation.setFieldValue('latitude', address.latitude);
    validation.setFieldValue('longitude', address.longitude);
    setLat(address.latitude);
    setLng(address.longitude);
    setAddresses([]);

    // CENTER MAP
    mapRef.current.setView([address.latitude, address.longitude], 15);

  }

  const onClickCreate = () => {
    setLocation(null);
    actionToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Menu")} breadcrumbItem={props.t("Locations")} />
          <Modal isOpen={searchAddressModal} toggle={() => setSearchAddressModal(!searchAddressModal)} size="md">
            <ModalHeader toggle={() => setSearchAddressModal(!searchAddressModal)} tag="h4">
              {props.t("Search-Address")}
            </ModalHeader>
            <ModalBody>
              <Form
                onChange={(e) => {
                  search(e.target.value);
                }}
              >
                <Container fluid>
                  <Row>
                    <Col lg={12}>
                      <Row className="mb-3">
                        <Col>
                          <Label className="form-label">{props.t("Address")}</Label>
                          <Input
                            name="address"
                            label="address"
                            placeholder={props.t("Address-Plc")}
                            type="text"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Form>
              <div className="mt-3">
                <ul className="list-group">
                  {!addressNotFound ? addresses.map((item, index) => {
                    return <li className="list-group-item" key={index} onClick={() => {
                      setAddress(item);
                      setSearchAddressModal(false);
                    }}>
                      {item.formattedAddress}
                    </li>
                  }) :
                    <li className="list-group-item">
                      {props.t("No-Result-Found")}
                    </li>
                  }
                </ul>
              </div>
            </ModalBody>
          </Modal>
          <Row>

            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  {error ? <Alert color="danger">{error.toString()}</Alert> : null}
                  <Card>
                    <CardBody>
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={all[0] != "Empty" ? all : []}
                        isGlobalFilter={true}
                        isShowingPageLength={true}
                        isAddUserList={true}
                        addUserTitle={props.t("Add-Location")}
                        iscustomPageSizeOptions={true}
                        handleUserClick={onClickCreate}
                        customPageSize={10}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-end mt-4"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }


            <Modal isOpen={modal} toggle={actionToggle} size="xl" centered>
              <ModalHeader toggle={actionToggle} tag="h4">
                {props.t("Add-Location")}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col lg={6}>
                        <Row className="mb-3">
                          <Col>
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Enter Location Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col className="mb-3">
                            <Label className="form-label">{props.t("Latitude")}</Label>
                            <Input
                              name="latitude"
                              type="text"
                              placeholder="41.015137"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.latitude || ""}
                              invalid={
                                validation.touched.latitude &&
                                  validation.errors.latitude
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          <Col className="ms-3 mb-3">
                            <Label className="form-label">{props.t("Longitude")}</Label>
                            <Input
                              name="longitude"
                              type="text"
                              placeholder="28.979530"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.longitude || ""}
                              invalid={
                                validation.touched.longitude &&
                                  validation.errors.longitude
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col className="mb-3">
                            <Label className="form-label">{props.t("Shift-Start")}</Label>
                            <Input
                              name="shiftStart"
                              type="text"
                              placeholder="HH:MM:SS"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.shiftStart || ""}
                              invalid={
                                validation.touched.shiftStart &&
                                  validation.errors.shiftStart
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          <Col className="ms-3 mb-3">
                            <Label className="form-label">{props.t("Shift-End")}</Label>
                            <Input
                              name="shiftEnd"
                              type="text"
                              placeholder="HH:MM:SS"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.shiftEnd || ""}
                              invalid={
                                validation.touched.shiftEnd &&
                                  validation.errors.shiftEnd
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Label className="form-label">{props.t("Address")}</Label>
                            <Input
                              name="address"
                              label="address"
                              placeholder="Enter Address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                  validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col className="mb-3">
                            <Label className="form-label">{props.t("City")}</Label>
                            <Input
                              name="city"
                              type="text"
                              placeholder="Enter City"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city &&
                                  validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          <Col className="ms-3 mb-3">
                            <Label className="form-label">{props.t("State")}</Label>
                            <Input
                              name="state"
                              type="text"
                              placeholder="Enter State"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.state || ""}
                              invalid={
                                validation.touched.state &&
                                  validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col className="mb-3">
                            <Label className="form-label">{props.t("Zip")}</Label>
                            <Input
                              name="zipCode"
                              type="text"
                              placeholder="Enter Zip Code"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zipCode || ""}
                              invalid={
                                validation.touched.zipCode &&
                                  validation.errors.zipCode
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          <Col className="ms-3 mb-3">
                            <Label className="form-label">{props.t("Country")}</Label>
                            <Input
                              name="country"
                              type="text"
                              placeholder="Enter Country"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.country || ""}
                              invalid={
                                validation.touched.country &&
                                  validation.errors.country
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <MapContainer center={[41.015137, 28.979530]} zoom={15} scrollWheelZoom={false}
                          style={{ width: "100%", height: "100%" }} ref={mapRef}>
                          <MapComponent />
                          <TileLayer
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            url={mapTileLayerUrl}
                          />
                          <Marker position={[lat, lng]} draggable={true} animate={true}
                            icon={mapIcon} eventHandlers={{
                              dragend: (e) => {
                                setLat(e.target._latlng.lat);
                                setLng(e.target._latlng.lng);

                                searchReverse(e.target._latlng.lat, e.target._latlng.lng);
                              }
                            }
                            }>
                          </Marker>
                        </MapContainer>
                      </Col>
                    </Row>
                    <Col className="text-end mt-3">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {props.t("Save")}
                      </button>
                    </Col>
                  </Container>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};


LocationsList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(LocationsList));