import { call, put, takeEvery } from "redux-saga/effects";

import { GET_ALL_SHIPMENTS, ADD_SHIPMENT } from "./actionTypes";

import {
  getAllShipmentsSuccess,
  getAllShipmentsFail,
  addShipmentSuccess,
  addShipmentFail,
} from "./actions";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../../helpers/jwt-token-access/accessToken";

import { getShipmentAll, postShipment } from "../../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchAllShipments({ payload: { skip, limit } }) {
  try {
    yield call(prepareAuthentications);
    const response = yield call(getShipmentAll, skip, limit)
    if (response.length > 0) {
      yield put(getAllShipmentsSuccess(response))
    } else {
      yield put(getAllShipmentsSuccess(["Empty"]))
    }
  } catch (error) {
    yield put(getAllShipmentsFail(error))
  }
}

function* onAddShipment({ payload: shipment }) {
  try {
    yield call(prepareAuthentications);
    const response = yield call(postShipment, shipment)
    yield put(addShipmentSuccess(response))
    toast.success("Shipment Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addShipmentFail(error))
    toast.error("Shipment Added Failed", { autoClose: 2000 });
  }
}

function* ShipmentListSaga() {
  yield takeEvery(GET_ALL_SHIPMENTS, fetchAllShipments)
  yield takeEvery(ADD_SHIPMENT, onAddShipment)
}

export default ShipmentListSaga;