import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";


import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getAllShipments as onGetAllShipments,
  addShipment as onAddShipment,
} from "store/shipment/list/actions";
import { getLocationAll, getVehicleAll } from "helpers/backend_helper";
import { shipmentStatuses } from "constants/status";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ShipmentList = props => {

  // meta title
  document.title = "Flio.ai";

  const dispatch = useDispatch();
  const [shipment, setShipment] = useState();

  const selectShipmentListState = state => state.shipments;
  const ShipemntListProperties = createSelector(
    selectShipmentListState,
    stateProperty => ({
      all: stateProperty.all,
      loading: stateProperty.loading,
      error: stateProperty.error,
    })
  );

  const { all, loading, error } = useSelector(ShipemntListProperties);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(loading);
  const [locationOptions, setLocationOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);

  useEffect(() => {
    if (all && !all.length) {
      dispatch(onGetAllShipments());
    }
  }, [dispatch, all]);

  const actionToggle = () => {
    setModal(!modal);
  }

  const onClickCreate = () => {
    setShipment(null);
    actionToggle();
  };

  // useEffect(() => {
  //   if (props.router.location.pathname === "/shipment") {
  //     dispatch(onGetAllShipments());
  //   }
  // }, [props.router.location]); 

  useEffect(() => {
    if (modal && !locationOptions.length) {
      setTimeout(async () => {
        const response = await getLocationAll(0, 100);
        const options = response.map(item => ({
          value: item.id,
          label: item.name
        }));
        setLocationOptions(options);
      }, 1000); // Simulating delay for demonstration purposes
    }
  }, [modal]);

  useEffect(() => {
    if (modal && !vehicleOptions.length) {
      setTimeout(async () => {
        const response = await getVehicleAll(0, 100);
        const options = response.map(item => ({
          value: item.id,
          label: item.name
        }));
        setVehicleOptions(options);
      }, 1000); // Simulating delay for demonstration purposes
    }
  }, [modal]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              <i className="fas fa-box" />
            </span>
          </div>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        },
      },
      {
        Header: props.t("Pickup"),
        accessor: 'pickupName',
        filterable: true,
        Cell: cellProps => {
          const pickupId = cellProps.row.original.pickupId;
          const pickupName = cellProps.row.original.pickupName;
          return <Link to={pickupId ? `/location/detail/${pickupId}` : '#'}
            style={{ color: 'inherit' }}>
            {pickupName ?? "-"}
          </Link>
        },
      },
      {
        Header: props.t("Delivery"),
        accessor: 'deliveryName',
        filterable: true,
        Cell: cellProps => {
          const deliveryId = cellProps.row.original.deliveryId;
          const deliveryName = cellProps.row.original.deliveryName;
          return <Link to={deliveryId ? `/location/detail/${deliveryId}` : '#'}
            style={{ color: 'inherit' }}>
            {deliveryName ?? "-"}
          </Link>
        },
      },
      {
        Header: props.t("Vehicle"),
        accessor: 'vehicleName',
        filterable: true,
        Cell: cellProps => {
          const vehicleId = cellProps.row.original.vehicleId;
          const vehicleName = cellProps.row.original.vehicleName;
          return vehicleName ?? "-";
        },
      },
      {
        Header: props.t("Amount"),
        accessor: 'amount',
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Shipment-Date")}`,
        accessor: 'shipmentDate',
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Pickup-Start-Time")}`,
        accessor: 'startTime',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Delivery-End-Time")}`,
        accessor: 'endTime',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      // {
      //   Header: 'Setup Duration',
      //   accessor: 'setupDuration',
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return cellProps.value ?? '';
      //   }
      // },
      // {
      //   Header: 'Service Duration',
      //   accessor: 'serviceDuration',
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return cellProps.value ?? '';
      //   }
      // },
      {
        Header: " ",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <span className={`badge badge-pill ${shipmentStatuses[cellProps.value].style}`}>
            {shipmentStatuses[cellProps.value].text}
          </span>
        },
      },
      {
        Header: " ",
        accessor: "id",
        filterable: false,
        Cell: cellProps => {
          return (
            <Link className="btn btn-outline-primary waves-effect waves-light btn-sm"
              to={`/shipment/detail/${cellProps.value}`}
            >
              {props.t("Details")}
            </Link>)
        },
      },
    ],
    []
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (shipment && shipment.name) || "",
      amount: (shipment && shipment.amount) || "",
      shipmentDate: (shipment && shipment.shipmentDate) || "",
      pickupId: (shipment && shipment.pickupId) || "",
      deliveryId: (shipment && shipment.deliveryId) || "",
      vehicleId: (shipment && shipment.vehicleId) || "",
      setupDuration: (shipment && shipment.setupDuration) || "",
      serviceDuration: (shipment && shipment.serviceDuration) || "",
      startTime: (shipment && shipment.startTime) || "",
      endTime: (shipment && shipment.endTime) || "",
      priority: (shipment && shipment.priority) || "",
      notes: (shipment && shipment.notes) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Shipment-Name-Rq")),
      amount: Yup.number().required(props.t("Amount-Rq")),
      shipmentDate: Yup.date().required(props.t("Shipment-Date-Rq")),
      pickupId: Yup.string().required(props.t("Pickup-Location-Rq")),
      deliveryId: Yup.string().required(props.t("Delivery-Location-Rq")),
      vehicleId: Yup.string(),
      setupDuration: Yup.number().default(0),
      serviceDuration: Yup.number().default(0),
      startTime: Yup.string(),
      endTime: Yup.string(),
      priority: Yup.string().default("medium").required(props.t("Priority-Rq")),
      notes: Yup.string(),
    }),
    onSubmit: values => {
      const newShipment = {
        name: values.name,
        amount: values.amount,
        shipmentDate: values.shipmentDate,
        pickupId: values.pickupId,
        deliveryId: values.deliveryId,
        vehicleId: values.vehicleId || null,
        setupDuration: values.setupDuration || 0,
        serviceDuration: values.serviceDuration || 0,
        startTime: values.startTime || null,
        endTime: values.endTime || null,
        priority: values.priority || "low",
        notes: values.notes || null,
      }
      dispatch(onAddShipment(newShipment));
      validation.resetForm();
      actionToggle();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Menu")} breadcrumbItem={props.t("Shipments")} />
          <Row>
            <Col lg="12">
              {error ? <Alert color="danger">{error.toString()}</Alert> : null}
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={all[0] != "Empty" ? all : []}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    addUserTitle={props.t("Add-Shipment")}
                    iscustomPageSizeOptions={true}
                    handleUserClick={onClickCreate}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"

                  />
                </CardBody>
              </Card>
            </Col>
            <Modal isOpen={modal} toggle={actionToggle}>
              <ModalHeader toggle={actionToggle} tag="h4">
                {props.t("Add-Shipment")}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Name")}</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder={props.t("Shipment-Name-Plc")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name}
                          invalid={
                            validation.touched.name &&
                            validation.errors.name
                          }
                        />
                        {validation.touched.name &&
                          validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Amount")}</Label>
                          <Input
                            name="amount"
                            type="number"
                            placeholder={props.t("Amount-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                            }
                          />
                          {validation.touched.amount &&
                            validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Shipment-Date")}</Label>
                          <Input
                            name="shipmentDate"
                            type="date"
                            placeholder={props.t("Shipment-Date-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shipmentDate}
                            invalid={
                              validation.touched.shipmentDate &&
                              validation.errors.shipmentDate
                            }
                          />
                          {validation.touched.shipmentDate &&
                            validation.errors.shipmentDate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shipmentDate}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Pickup-Location")}</Label>
                          <Input
                            name="pickupId"
                            type="select"
                            placeholder={props.t("Select-Pickup-Location")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pickupId}
                            invalid={
                              validation.touched.pickupId &&
                              validation.errors.pickupId
                            }
                          >
                            <option value="">{props.t("Select-Pickup-Location")}</option>
                            {locationOptions.map(item => (
                              <option key={item.value}  value={item.value}>{item.label}</option>
                            ))}
                          </Input>
                          {validation.touched.pickupId &&
                            validation.errors.pickupId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.pickupId}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Delivery-Location")}</Label>
                          <Input
                            name="deliveryId"
                            type="select"
                            placeholder={props.t("Select-Delivery-Location")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.deliveryId}
                            invalid={
                              validation.touched.deliveryId &&
                              validation.errors.deliveryId
                            }
                          >
                            <option value="">{props.t("Select-Delivery-Location")}</option>
                            {locationOptions.map(item => (
                              <option key={item.value}  value={item.value}>{item.label}</option>
                            ))}
                          </Input>
                          {validation.touched.deliveryId &&
                            validation.errors.deliveryId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.deliveryId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Vehicle")}</Label>
                          <Input
                            name="vehicleId"
                            type="select"
                            placeholder={props.t("Select-Vehicle")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.vehicleId}
                            invalid={
                              validation.touched.vehicleId &&
                              validation.errors.vehicleId
                            }
                          >
                            <option value="">Select Vehicle</option>
                            {vehicleOptions.map(item => (
                              <option key={item.value} value={item.value}>{item.label}</option>
                            ))}
                          </Input>
                          {validation.touched.vehicleId &&
                            validation.errors.vehicleId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.vehicleId}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Priority")}</Label>
                          <Input
                            name="priority"
                            type="select"
                            placeholder={props.t("Select-Priority")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.priority}
                            invalid={
                              validation.touched.priority &&
                              validation.errors.priority
                            }
                          >
                            <option value="">{props.t("Select-Priority")}</option>
                            <option value="low">{props.t("Low")}</option>
                            <option value="medium">{props.t("Medium")}</option>
                            <option value="high">{props.t("High")}</option>
                          </Input>
                          {validation.touched.priority &&
                            validation.errors.priority ? (
                            <FormFeedback type="invalid">
                              {validation.errors.priority}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Setup-Duration")}</Label>
                          <Input
                            name="setupDuration"
                            type="number"
                            placeholder={props.t("Setup-Duration-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.setupDuration}
                            invalid={
                              validation.touched.setupDuration &&
                              validation.errors.setupDuration
                            }
                          />
                          {validation.touched.setupDuration &&
                            validation.errors.setupDuration ? (
                            <FormFeedback type="invalid">
                              {validation.errors.setupDuration}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Service-Duration")}</Label>
                          <Input
                            name="serviceDuration"
                            type="number"
                            placeholder={props.t("Service-Duration-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.serviceDuration}
                            invalid={
                              validation.touched.serviceDuration &&
                              validation.errors.serviceDuration
                            }
                          />
                          {validation.touched.serviceDuration &&
                            validation.errors.serviceDuration ? (
                            <FormFeedback type="invalid">
                              {validation.errors.serviceDuration}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Pickup")} {props.t("Start-Time")}</Label>
                          <Input
                            name="startTime"
                            type="time"
                            placeholder={props.t("Start-Time-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.startTime}
                            invalid={
                              validation.touched.startTime &&
                              validation.errors.startTime
                            }
                          />
                          {validation.touched.startTime &&
                            validation.errors.startTime ? (
                            <FormFeedback type="invalid">
                              {validation.errors.startTime}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Delivery")} {props.t("End-Time")}</Label>
                          <Input
                            name="endTime"
                            type="time"
                            placeholder={props.t("End-Time-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.endTime}
                            invalid={
                              validation.touched.endTime &&
                              validation.errors.endTime
                            }
                          />
                          {validation.touched.endTime &&
                            validation.errors.endTime ? (
                            <FormFeedback type="invalid">
                              {validation.errors.endTime}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3 col">
                        <Label className="form-label">{props.t("Notes")}</Label>
                        <Input
                          name="notes"
                          type="text"
                          placeholder={props.t("Notes-Plc")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.notes}
                          invalid={
                            validation.touched.notes &&
                            validation.errors.notes
                          }
                        />
                        {validation.touched.notes &&
                          validation.errors.notes ? (
                          <FormFeedback type="invalid">
                            {validation.errors.notes}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

ShipmentList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(ShipmentList));
