import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Col,
  Form,
  Modal,
  ModalHeader,
  Row,
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dropzone from "react-dropzone";

import {
  getOptimizerWorkload as onGetOptimizerWorkload,
} from "store/dashboard/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import Optimizer from "pages/Optimizer";
import { donwloadOptimizerTemplate } from "helpers/backend_helper";

const Dashboard = props => {
  //meta title
  document.title = "Flio.ai";

  const dispatch = useDispatch();
  const selectDashboardState = state => state.dashboard;
  const dashboardProperties = createSelector(
    selectDashboardState,
    stateProperty => ({
      workloads: stateProperty.workloads,
      loading: stateProperty.loading,
      error: stateProperty.error,
    })
  );

  const { workloads, loading, error } = useSelector(dashboardProperties);
  const [modal, setModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isManual, setIsManual] = useState(false);

  const today = new Date();
  const tomorrow = new Date();
  const timezone = today.getTimezoneOffset();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedDate, setSelectedDate] = useState(today);
  const [workloadDate, setWorkloadDate] = useState(null);
  const [dateStr, setDateStr] = useState("Today's");
  const [overviewText, setOverviewText] = useState(props.t("No-Routes-Found"));

  const toggle = () => setModal(!modal);
  const fileToggle = () => setFileModal(!fileModal);

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const donwloadTemplate = async () => {
    await donwloadOptimizerTemplate("optimizer_template.xlsx");
  }

  useEffect(() => {
    const dateLimit = 3 * 24 * 60 * 60 * 1000;
    if (!workloadDate || (selectedDate - workloadDate >= dateLimit)) {
      dispatch(onGetOptimizerWorkload(formatDate(selectedDate), 5));
      setWorkloadDate(selectedDate - 1);
    }

  }, [selectedDate, dispatch]);

  useEffect(() => {
    const workload = workloads.filter(item => item.date === formatDate(selectedDate))[0];
    const overview = (!workload || (workload.planned === 0 && workload.accepted === 0)) ?
      props.t("No-Routes-Found") :
      `• ${props.t("Planned-Routes")}: ${workload.planned} \n• ${props.t("Accepted-Routes")}: ${workload.accepted}`;
    setOverviewText(overview);
  }, [selectedDate, workloads, dispatch]);

  const sendFile = async () => {
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    setFile(formData);
    setIsManual(false);
    setFileModal(false);
    setModal(true);
  }

  const sendManualData = async () => {
    setIsManual(true);
    setFileModal(false);
    setModal(true);
  }

  // convert date to YYYY-MM-DD
  const formatDate = (date) => {
    const copyDate = new Date(date);
    copyDate.setMinutes(copyDate.getMinutes() - timezone);
    return copyDate.toISOString().split("T")[0];
  }

  const DateToString = (date) => {
    return date.toLocaleDateString(props.t("Locale-Date"),
      { weekday: "short", day: "numeric", month: "long" });
  }

  const incrementDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
    if (selectedDate.toDateString() === today.toDateString()) {
      setDateStr("Today's");
    }
    else if (selectedDate.toDateString() === tomorrow.toDateString()) {
      setDateStr("Tomorrow's");
    }
    else {
      setDateStr(DateToString(selectedDate));
    }
  }

  const decrementDate = () => {
    if (selectedDate < new Date()) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
    if (selectedDate.toDateString() === today.toDateString()) {
      setDateStr("Today's");
    }
    else if (selectedDate.toDateString() === tomorrow.toDateString()) {
      setDateStr("Tomorrow's");
    }
    else {
      setDateStr(DateToString(selectedDate));
    }
  }


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Dashboard")} breadcrumbItem={props.t("Dashboard")} />
          <Container fluid className="d-flex justify-content-center">
            <Col lg={6}>
              <Card>
                <CardBody>
                  <Col className="d-flex justify-content-between">
                    <p className="text-muted">Demo User {selectedDate < tomorrow && "• " + DateToString(selectedDate)}</p>
                    <div>
                      <Link className="btn btn-sm"
                        onClick={decrementDate} >
                        <i className="bx bx-chevron-left" style={{ fontSize: "20px" }} />
                      </Link>
                      <Link className="btn btn-sm"
                        onClick={incrementDate} >
                        <i className="bx bx-chevron-right" style={{ fontSize: "20px" }} />
                      </Link>
                    </div>
                  </Col>

                  <h2>{dateStr === "Today's" ? props.t("Today's") :
                    (dateStr === "Tomorrow's" ? props.t("Tomorrow's") :
                      DateToString(selectedDate))}
                    {" " + props.t("Overview")}
                  </h2>
                  <p className="text-muted">{overviewText}</p>

                  <Container>
                    <Row className="justify-content-center mt-5 mb-2">
                      <Link className="btn btn-block btn-primary"
                        onClick={fileToggle} >
                        {props.t("Create-Spreadsheet")}
                      </Link>
                      <Link className="btn btn-block btn-outline-primary mt-3"
                        onClick={sendManualData} >
                        {props.t("Create-Manually")}
                      </Link>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </Container>
        <Modal isOpen={modal} toggle={toggle} size="xl" fullscreen={true}>
          <ModalHeader toggle={toggle}>{`${props.t("Date")}: ${selectedDate.toLocaleDateString("en-GB")}`}</ModalHeader>
          <Optimizer date={formatDate(selectedDate)} file={!isManual && file} t={props.t} />
        </Modal>
        <Modal isOpen={fileModal} toggle={fileToggle} size="xl"
          onClosed={() => setselectedFiles([])}>
          <Card>
            <CardBody>
              {/* <CardTitle className="mb-3">Upload Excel File</CardTitle> */}

              <CardTitle className="mb-3">
                <div className="d-flex justify-content-between">
                  {props.t("Upload-File")}
                  <Link className="btn-primary btn-link" onClick={donwloadTemplate}>
                    <p style={{ fontWeight: "normal", fontSize: "14px" }}>{props.t("Download-Template")}</p>
                  </Link>
                </div>
              </CardTitle>
              <Form>
                <Dropzone maxFiles={1}
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>{props.t("Drop-File")}</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
                {selectedFiles.length > 0 &&
                  <Link className="btn btn-block btn-primary mt-3 d-flex align-items-center justify-content-center"
                    onClick={sendFile} >
                    {props.t("Create-Route")}
                  </Link>}
              </Form>
            </CardBody>
          </Card>
        </Modal>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Dashboard));
