import {
    GET_ALL_VEHICLES,
    GET_ALL_VEHICLES_FAIL,
    GET_ALL_VEHICLES_SUCCESS,
    ADD_VEHICLE,
    ADD_VEHICLE_FAIL,
    ADD_VEHICLE_SUCCESS,
    UPDATE_VEHICLE,
    UPDATE_VEHICLE_FAIL,
    UPDATE_VEHICLE_SUCCESS,
    DELETE_VEHICLE,
    DELETE_VEHICLE_FAIL,
    DELETE_VEHICLE_SUCCESS,
    GET_VEHICLE_SHIPMENTS_DETAIL,
    GET_VEHICLE_SHIPMENTS_DETAIL_FAIL,
    GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS
} from "./actionTypes";

export const getAllVehicles = (skip, limit) => ({
    type: GET_ALL_VEHICLES,
    payload: { skip, limit }
})

export const getAllVehiclesSuccess = vehicles => ({
    type: GET_ALL_VEHICLES_SUCCESS,
    payload: vehicles,
})

export const getAllVehiclesFail = error => ({
    type: GET_ALL_VEHICLES_FAIL,
    payload: error,
})

export const addVehicle = vehicle => ({
    type: ADD_VEHICLE,
    payload: vehicle,
})

export const addVehicleSuccess = vehicle => ({
    type: ADD_VEHICLE_SUCCESS,
    payload: vehicle,
})

export const addVehicleFail = error => ({
    type: ADD_VEHICLE_FAIL,
    payload: error,
})

export const updateVehicle = vehicle => ({
    type: UPDATE_VEHICLE,
    payload: vehicle,
})

export const updateVehicleSuccess = vehicle => ({
    type: UPDATE_VEHICLE_SUCCESS,
    payload: vehicle,
})

export const updateVehicleFail = error => ({
    type: UPDATE_VEHICLE_FAIL,
    payload: error,
})

export const deleteVehicle = id => ({
    type: DELETE_VEHICLE,
    payload: id,
})

export const deleteVehicleSuccess = id => ({
    type: DELETE_VEHICLE_SUCCESS,
    payload: id,
})

export const deleteVehicleFail = error => ({
    type: DELETE_VEHICLE_FAIL,
    payload: error,
})

export const getVehicleShipmentsDetail = (id, skip, limit) => ({
    type: GET_VEHICLE_SHIPMENTS_DETAIL,
    payload: { id, skip, limit },
})

export const getVehicleShipmentsDetailSuccess = vehicleShipmentsDetail => ({
    type: GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS,
    payload: vehicleShipmentsDetail,
})

export const getVehicleShipmentsDetailFail = error => ({
    type: GET_VEHICLE_SHIPMENTS_DETAIL_FAIL,
    payload: error,
})