import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_ALL_VEHICLES, 
    ADD_VEHICLE, 
    UPDATE_VEHICLE,
    DELETE_VEHICLE,
    GET_VEHICLE_SHIPMENTS_DETAIL
} from "./actionTypes";

import {
    getAllVehiclesSuccess,
    getAllVehiclesFail,
    addVehicleSuccess,
    addVehicleFail,
    updateVehicleSuccess,
    updateVehicleFail,
    deleteVehicleSuccess,
    deleteVehicleFail,
    getVehicleShipmentsDetailSuccess,
    getVehicleShipmentsDetailFail
} from "./actions";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../../helpers/jwt-token-access/accessToken";

import { 
    getVehicleAll, 
    postVehicle, 
    putVehicle,
    delVehicle,
    getVehicleShipmentsDetail 
} from "../../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchAllVehicles({ payload: { skip, limit } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getVehicleAll, skip, limit)
        if (response.length > 0) {
            yield put(getAllVehiclesSuccess(response))
        } else {
            yield put(getAllVehiclesSuccess(["Empty"]))
        }
    } catch (error) {
        yield put(getAllVehiclesFail(error))
    }
}

function* onAddVehicle({ payload: vehicle }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(postVehicle, vehicle)
        yield put(addVehicleSuccess(response))
        toast.success("Vehicle Added Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addVehicleFail(error))
        toast.error("Vehicle Added Failed", { autoClose: 2000 });
    }
}

function* onUpdateVehicle({ payload: vehicle }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(putVehicle, vehicle)
        yield put(updateVehicleSuccess(response))
        toast.success("Vehicle Updated Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(updateVehicleFail(error))
        toast.error("Vehicle Updated Failed", { autoClose: 2000 });
    }
}

function* onDeleteVehicle({ payload: id }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(delVehicle, id)
        yield put(deleteVehicleSuccess(response ? id : null))
        toast.success("Vehicle Deleted Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(deleteVehicleFail(error))
        toast.error("Vehicle Deleted Failed", { autoClose: 2000 });
    }
}

function* fetchVehicleShipmentsDetail({ payload: { id, skip, limit } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getVehicleShipmentsDetail, id, skip, limit)
        if (response.length > 0) {
            yield put(getVehicleShipmentsDetailSuccess(response))
        } else {
            yield put(getVehicleShipmentsDetailSuccess(["Empty"]))
        }
    } catch (error) {
        yield put(getVehicleShipmentsDetailFail(error))
    }
}

function* VehicleListSaga() {
    yield takeEvery(GET_ALL_VEHICLES, fetchAllVehicles);
    yield takeEvery(ADD_VEHICLE, onAddVehicle);
    yield takeEvery(UPDATE_VEHICLE, onUpdateVehicle);
    yield takeEvery(DELETE_VEHICLE, onDeleteVehicle);
    yield takeEvery(GET_VEHICLE_SHIPMENTS_DETAIL, fetchVehicleShipmentsDetail);
}

export default VehicleListSaga;