/* OPTIMIZER ROUTE MANUALLY DATA */

export const GET_OPTIMIZER_PAYLOAD = "GET_OPTIMIZER_PAYLOAD";
export const GET_OPTIMIZER_PAYLOAD_SUCCESS = "GET_OPTIMIZER_PAYLOAD_SUCCESS";
export const GET_OPTIMIZER_PAYLOAD_FAIL = "GET_OPTIMIZER_PAYLOAD_FAIL";

export const OPTIMIZE_ROUTE = "OPTIMIZE_ROUTE";
export const OPTIMIZE_ROUTE_SUCCESS = "OPTIMIZE_ROUTE_SUCCESS";
export const OPTIMIZE_ROUTE_FAIL = "OPTIMIZE_ROUTE_FAIL";

export const UPLOAD_OPTIMIZER_TEMPLATE = "UPLOAD_OPTIMIZER_TEMPLATE";
export const UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS = "UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS";
export const UPLOAD_OPTIMIZER_TEMPLATE_FAIL = "UPLOAD_OPTIMIZER_TEMPLATE_FAIL";

export const PLAN_OPTIMIZER_ROUTE = "PLAN_OPTIMIZER_ROUTE";
export const PLAN_OPTIMIZER_ROUTE_SUCCESS = "PLAN_OPTIMIZER_ROUTE_SUCCESS";
export const PLAN_OPTIMIZER_ROUTE_FAIL = "PLAN_OPTIMIZER_ROUTE_FAIL";

export const RESET_OPTIMIZER_DATA = "RESET_OPTIMIZER_DATA";
