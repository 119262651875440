/* ALL VEHICLES */

export const GET_ALL_VEHICLES = "GET_ALL_VEHICLES"
export const GET_ALL_VEHICLES_SUCCESS = "GET_ALL_VEHICLES_SUCCESS"
export const GET_ALL_VEHICLES_FAIL = "GET_ALL_VEHICLES_FAIL"

/* ADD NEW VEHICLE */

export const ADD_VEHICLE = "ADD_VEHICLE"
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS"
export const ADD_VEHICLE_FAIL = "ADD_VEHICLE_FAIL"

/* UPDATE VEHICLE */

export const UPDATE_VEHICLE = "UPDATE_VEHICLE"
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS"
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL"

/* DELETE VEHICLE */

export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_FAIL = "DELETE_VEHICLE_FAIL"

/* GET VEHICLE ACTIVE SHIPMENTS */

export const GET_VEHICLE_SHIPMENTS_DETAIL = "GET_VEHICLE_SHIPMENTS_DETAIL"
export const GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS = "GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS"
export const GET_VEHICLE_SHIPMENTS_DETAIL_FAIL = "GET_VEHICLE_SHIPMENTS_DETAIL_FAIL"