import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Table
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getAllVehicles as onGetAllVehicles,
  addVehicle as onAddVehicle,
  updateVehicle as onUpdateVehicle,
  deleteVehicle as onDeleteVehicle,
  getVehicleShipmentsDetail as onGetVehicleShipmentsDetail,
} from "store/vehicle/list/actions";
import { vehicleStatuses } from "constants/status";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const VehicleList = props => {

  //meta title
  document.title = "Flio.ai";

  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState(null);

  const selectVehicleListState = state => state.vehicles;
  const VehicleListProperties = createSelector(
    selectVehicleListState,
    stateProperty => ({
      all: stateProperty.all,
      shipments: stateProperty.shipments,
      loading: stateProperty.loading,
      error: stateProperty.error,
    })
  );

  const { all, shipments, loading, error } = useSelector(VehicleListProperties);
  const [isEdit, setEdit] = useState(loading);

  const [modal, setModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isLoading, setLoading] = useState(loading);
  const [isShipmentLoading, setShipmentLoading] = useState(true);

  useEffect(() => {
    if (all && !all.length) {
      dispatch(onGetAllVehicles(0, 100));
    }
  }, [dispatch, all]);

  useEffect(() => {
    if (shipments && shipments.length) {
      setShipmentLoading(false);
    }
  }, [shipments]);

  const toggle = () => {
    setModal(!modal);
  }

  const toggleShipment = () => {
    setShipmentModal(!shipmentModal);
  }

  const onClickCreate = () => {
    setVehicle(null);
    setEdit(false);
    toggle();
  }

  const onClickEdit = (vehicle) => {
    setVehicle(vehicle);
    setEdit(true);
    toggle();
  }

  const onClickView = (vehicle) => {
    setShipmentLoading(true);
    setVehicle(vehicle);
    dispatch(onGetVehicleShipmentsDetail(vehicle.id));
    toggleShipment();
  }

  const onClickDelete = (vehicle) => {
    setVehicle(vehicle);
    setDeleteModal(true);
  }
  const handleDeletejob = () => {
    if (vehicle && vehicle.id) {
      dispatch(onDeleteVehicle(vehicle.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: " ",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              <i className={"fas fa-" + row.original.type} />
            </span>
          </div>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        },
      },
      {
        Header: props.t("Type"),
        accessor: "type",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Plate"),
        accessor: "plate",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Person"),
        accessor: "person",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Capacity"),
        accessor: 'capacity',
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: props.t("Shift-Start"),
        accessor: "shiftStart",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Shift-End"),
        accessor: "shiftEnd",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: " ",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <span className={`badge badge-pill ${vehicleStatuses[cellProps.value].style}`}>
            {vehicleStatuses[cellProps.value].text}
          </span>
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-2">
              <Link
                className="btn btn-sm btn-soft-primary"
                onClick={() => {
                  const vehicleData = cellProps.row.original;
                  onClickView(vehicleData);
                }}
              >
                <i className="mdi mdi-clipboard-text-search-outline" id="viewtooltip"></i>
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  {props.t("Details")}
                </UncontrolledTooltip>
              </Link>
              <Link
                className="btn btn-sm btn-soft-success"
                onClick={() => {
                  const vehicleData = cellProps.row.original;
                  onClickEdit(vehicleData);
                }}
              >
                <i className="mdi mdi-pencil" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const vehicleData = cellProps.row.original;
                  onClickDelete(vehicleData);
                }}
              >
                <i className="mdi mdi-delete-outline" id="deletetooltip"></i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const shipmentColumns = useMemo(
    () => [
      {
        Header: " ",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              <i className="fas fa-box" />
            </span>
          </div>
        ),
      },
      {
        Header: props.t("Name"),
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.row.original.name ?? '-';
        }
      },
      {
        Header: props.t("Pickup"),
        filterable: true,
        Cell: cellProps => {
          const pickupId = cellProps.row.original.pickupId;
          const pickupName = cellProps.row.original.pickupName;
          return <Link to={`/location/detail/${pickupId}`}
            style={{ color: 'inherit' }}>
            {pickupName ?? "-"}
          </Link>
        },
      },
      {
        Header: props.t("Delivery"),
        filterable: true,
        Cell: cellProps => {
          const deliveryId = cellProps.row.original.deliveryId;
          const deliveryName = cellProps.row.original.deliveryName;
          return <Link to={`/location/detail/${deliveryId}`}
            style={{ color: 'inherit' }}>
            {deliveryName ?? "-"}
          </Link>
        },
      },
      {
        Header: props.t("Amount"),
        accessor: 'amount',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: props.t("Shipment-Date"),
        accessor: 'shipmentDate',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      // {
      //   Header: 'Pickup Start Time',
      //   accessor: 'startTime',
      //   filterable: true,
      //   Cell: cellProps => {
      //     return cellProps.value ?? '';
      //   }
      // },
      // {
      //   Header: 'Delivery End Time',
      //   accessor: 'endTime',
      //   filterable: true,
      //   Cell: cellProps => {
      //     return cellProps.value ?? '';
      //   }
      // },
      {
        Header: " ",
        accessor: "id",
        filterable: true,
        Cell: cellProps =>
          <Link to={`/shipment/detail/${cellProps.value}`}
            className="btn btn-sm btn-soft-primary">
            <i className="mdi mdi-eye-outline" id="viewtooltip">
              <UncontrolledTooltip placement="top" target="viewtooltip">
                {props.t("Shipment-Detail")}
              </UncontrolledTooltip>
            </i>
          </Link>
      },
    ],
    []
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (vehicle && vehicle.name) || "",
      type: (vehicle && vehicle.type) || "",
      capacity: (vehicle && vehicle.capacity) || "",
      fixedCost: (vehicle && vehicle.fixedCost) || "",
      perKmCost: (vehicle && vehicle.perKmCost) || "",
      perHourCost: (vehicle && vehicle.perHourCost) || "",
      maxTasks: (vehicle && vehicle.maxTasks) || "",
      maxDistance: (vehicle && vehicle.maxDistance) || "",
      maxDuration: (vehicle && vehicle.maxDuration) || "",
      plate: (vehicle && vehicle.plate) || "",
      person: (vehicle && vehicle.person) || "",
      shiftStart: (vehicle && vehicle.shiftStart) || "",
      shiftEnd: (vehicle && vehicle.shiftEnd) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Name-Rq")),
      type: Yup.string().required(props.t("Type-Rq")),
      capacity: Yup.number().required(props.t("Capacity-Rq")),
      fixedCost: Yup.number(),
      perKmCost: Yup.number(),
      perHourCost: Yup.number(),
      maxTasks: Yup.number(),
      maxDistance: Yup.number(),
      maxDuration: Yup.number(),
      plate: Yup.string(),
      person: Yup.string(),
      shiftStart: Yup.string(),
      shiftEnd: Yup.string(),
    }),
    onSubmit: values => {
      if (isEdit) {
        const data = {
          id: vehicle.id,
          name: values.name,
          type: values.type,
          capacity: values.capacity,
          fixedCost: values.fixedCost || 0,
          perKmCost: values.perKmCost || 1,
          perHourCost: values.perHourCost || 0,
          maxTasks: values.maxTasks || null,
          maxDistance: values.maxDistance || null,
          maxDuration: values.maxDuration || null,
          plate: values.plate || null,
          person: values.person || null,
          shiftStart: values.shiftStart || null,
          shiftEnd: values.shiftEnd || null,
          userId: vehicle.userId,
          status: vehicle.status,
          createdAt: vehicle.createdAt,
          updatedAt: vehicle.updatedAt,
        }
        dispatch(onUpdateVehicle(data));
      } else {
        const data = {
          name: values.name,
          type: values.type,
          capacity: values.capacity,
          fixedCost: values.fixedCost || 0,
          perKmCost: values.perKmCost || 1,
          perHourCost: values.perHourCost || 0,
          maxTasks: values.maxTasks || null,
          maxDistance: values.maxDistance || null,
          maxDuration: values.maxDuration || null,
          plate: values.plate || null,
          person: values.person || null,
          shiftStart: values.shiftStart || null,
          shiftEnd: values.shiftEnd || null,
        }
        console.log(JSON.stringify(data));

        dispatch(onAddVehicle(data));
      }
      validation.resetForm();
      toggle();
    },
  });

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
        message={props.t("Delete-Vehicle-Message")}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Menu")} breadcrumbItem={props.t("Vehicles")} />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} /> :
                <Col lg="12">
                  {error ? <Alert color="danger">{error.toString()}</Alert> : null}
                  <Card>
                    <CardBody>
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={all[0] === "Empty" ? [] : all}
                        isGlobalFilter={true}
                        isShowingPageLength={true}
                        isAddUserList={true}
                        addUserTitle={props.t("Add-Vehicle")}
                        iscustomPageSizeOptions={true}
                        handleUserClick={onClickCreate}
                        customPageSize={10}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-end mt-4"

                      />
                    </CardBody>
                  </Card>
                </Col>
            }
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? props.t("Edit-Vehicle") : props.t("Add-Vehicle")}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Name")}</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder={props.t("Vehicle-Name-Plc")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name}
                          invalid={
                            validation.touched.name &&
                            validation.errors.name
                          }
                        />
                        {validation.touched.name &&
                          validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Type")}</Label>
                          <Input
                            name="type"
                            type="select"
                            placeholder={props.t("Type-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.type}
                            invalid={
                              validation.touched.type &&
                              validation.errors.type
                            }
                          >
                            <option value="">{props.t("Type-Plc")}</option>
                            <option value="truck">{props.t("Truck")}</option>
                            {/* <option value="van">Van</option> */}
                            <option value="car">{props.t("Car")}</option>
                            {/* <option value="motor">Motor</option> */}
                          </Input>
                          {validation.touched.type &&
                            validation.errors.type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.type}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Status")}</Label>
                          <Input
                            name="type"
                            type="select"
                            placeholder={props.t("Select-Status")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                            invalid={
                              validation.touched.type &&
                              validation.errors.type
                            }
                          >
                            <option value="100">{props.t("Free")}</option>
                            <option value="200">{props.t("Working")}</option>
                            <option value="300">{props.t("Busy")}</option>
                            <option value="400">{props.t("Inactive")}</option>
                          </Input>
                          {validation.touched.type &&
                            validation.errors.type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Capacity")}</Label>
                          <Input
                            name="capacity"
                            type="number"
                            placeholder={props.t("Capacity-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.capacity}
                            invalid={
                              validation.touched.capacity &&
                              validation.errors.capacity
                            }
                          />
                          {validation.touched.capacity &&
                            validation.errors.capacity ? (
                            <FormFeedback type="invalid">
                              {validation.errors.capacity}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Plate")}</Label>
                          <Input
                            name="plate"
                            type="text"
                            placeholder={props.t("Plate-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.plate}
                            invalid={
                              validation.touched.plate &&
                              validation.errors.plate
                            }
                          />
                          {validation.touched.plate &&
                            validation.errors.plate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.plate}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Person")}</Label>
                        <Input
                          name="person"
                          type="text"
                          placeholder={props.t("Person-Plc")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.person}
                          invalid={
                            validation.touched.person &&
                            validation.errors.person
                          }
                        />
                        {validation.touched.person &&
                          validation.errors.person ? (
                          <FormFeedback type="invalid">
                            {validation.errors.person}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Shift-Start")}</Label>
                          <Input
                            name="shiftStart"
                            type="time"
                            placeholder={props.t("Shift-Start-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shiftStart}
                            invalid={
                              validation.touched.shiftStart &&
                              validation.errors.shiftStart
                            }
                          />
                          {validation.touched.shiftStart &&
                            validation.errors.shiftStart ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shiftStart}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Shift-End")}</Label>
                          <Input
                            name="shiftEnd"
                            type="time"
                            placeholder={props.t("Shift-End-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shiftEnd}
                            invalid={
                              validation.touched.shiftEnd &&
                              validation.errors.shiftEnd
                            }
                          />
                          {validation.touched.shiftEnd &&
                            validation.errors.shiftEnd ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shiftEnd}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Fixed-Cost")}</Label>
                          <Input
                            name="fixedCost"
                            type="number"
                            placeholder={props.t("Fixed-Cost-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fixedCost}
                            invalid={
                              validation.touched.fixedCost &&
                              validation.errors.fixedCost
                            }
                          />
                          {validation.touched.fixedCost &&
                            validation.errors.fixedCost ? (
                            <FormFeedback type="invalid">
                              {validation.errors.fixedCost}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Max-Tasks")}</Label>
                          <Input
                            name="maxTasks"
                            type="number"
                            placeholder={props.t("Max-Tasks-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.maxTasks}
                            invalid={
                              validation.touched.maxTasks &&
                              validation.errors.maxTasks
                            }
                          />
                          {validation.touched.maxTasks &&
                            validation.errors.maxTasks ? (
                            <FormFeedback type="invalid">
                              {validation.errors.maxTasks}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Per-Km-Cost")}</Label>
                          <Input
                            name="perKmCost"
                            type="number"
                            placeholder={props.t("Per-Km-Cost-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.perKmCost}
                            invalid={
                              validation.touched.perKmCost &&
                              validation.errors.perKmCost
                            }
                          />
                          {validation.touched.perKmCost &&
                            validation.errors.perKmCost ? (
                            <FormFeedback type="invalid">
                              {validation.errors.perKmCost}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Max-Distance")}</Label>
                          <Input
                            name="maxDistance"
                            type="number"
                            placeholder={props.t("Max-Distance-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.maxDistance}
                            invalid={
                              validation.touched.maxDistance &&
                              validation.errors.maxDistance
                            }
                          />
                          {validation.touched.maxDistance &&
                            validation.errors.maxDistance ? (
                            <FormFeedback type="invalid">
                              {validation.errors.maxDistance}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-3 col">
                          <Label className="form-label">{props.t("Per-Hour-Cost")}</Label>
                          <Input
                            name="perHourCost"
                            type="number"
                            placeholder={props.t("Per-Hour-Cost-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.perHourCost}
                            invalid={
                              validation.touched.perHourCost &&
                              validation.errors.perHourCost
                            }
                          />
                          {validation.touched.perHourCost &&
                            validation.errors.perHourCost ? (
                            <FormFeedback type="invalid">
                              {validation.errors.perHourCost}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="ms-3 mb-3 col">
                          <Label className="form-label">{props.t("Max-Duration")}</Label>
                          <Input
                            name="maxDuration"
                            type="number"
                            placeholder={props.t("Max-Duration-Plc")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.maxDuration}
                            invalid={
                              validation.touched.maxDuration &&
                              validation.errors.maxDuration
                            }
                          />
                          {validation.touched.maxDuration &&
                            validation.errors.maxDuration ? (
                            <FormFeedback type="invalid">
                              {validation.errors.maxDuration}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          {isEdit ? props.t("Edit") : props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <Modal isOpen={shipmentModal} toggle={toggleShipment} size="lg">
              <ModalHeader toggle={toggleShipment} tag="h4">
                {props.t("Vehicle-Detail")}
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-between flex-column">
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Name")} :</th>
                        <td>{vehicle?.name ?? "-"}</td>
                        <th scope="row">{props.t("Status")} :</th>
                        <td>
                          <span className={`badge badge-pill ${vehicleStatuses[vehicle?.status ?? 400].style}`}>
                            {vehicleStatuses[vehicle?.status ?? 400].text}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Type")} :</th>
                        <td>{vehicle?.type ?? "-"}</td>
                        <th scope="row">{props.t("Capacity")} :</th>
                        <td>{vehicle?.capacity ?? "-"}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Plate")} :</th>
                        <td>{vehicle?.plate ?? "-"}</td>
                        <th scope="row">{props.t("Person")} :</th>
                        <td>{vehicle?.person ?? "-"}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Shift-Start")} :</th>
                        <td>{vehicle?.shiftStart ?? "-"}</td>
                        <th scope="row">{props.t("Shift-End")} :</th>
                        <td>{vehicle?.shiftEnd ?? "-"}</td>,
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Fixed-Cost")} :</th>
                        <td>{vehicle?.fixedCost ?? "-"}</td>
                        <th scope="row">{props.t("Max-Tasks")} :</th>
                        <td>{vehicle?.maxTasks ?? "-"}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Per-Km-Cost")} :</th>
                        <td>{vehicle?.perKmCost ?? "-"}</td>
                        <th scope="row">{props.t("Max-Distance")} :</th>
                        <td>{vehicle?.maxDistance ?? "-"}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Per-Hour-Cost")} :</th>
                        <td>{vehicle?.perHourCost ?? "-"}</td>
                        <th scope="row">{props.t("Max-Duration")} :</th>
                        <td>{vehicle?.maxDuration ?? "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {
                  isShipmentLoading ? <Spinners setLoading={setShipmentLoading} />
                    : shipments[0] === "Empty" ?
                      <Container fluid className="text-center d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                        <h5>{props.t("No-Active-Shipments")}</h5>
                      </Container>
                      :
                      <TableContainer
                        columns={shipmentColumns}
                        data={shipments}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        isPagination={false}
                        iscustomPageSizeOptions={false}
                        isShowingPageLength={true}
                        customPageSize={10}
                        tableClass="table align-middle nowrap mt-2"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination justify-content-end pagination-rounded"
                      />
                }
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );

};

VehicleList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(VehicleList));