import { postLogin } from "../backend_helper";
import { useNavigate } from "react-router-dom";

const isUserLoggedIn = () => {
  const token = localStorage.getItem("userAuth");
  if (token) return JSON.parse(token);
  return null;
};

const getLoggedInTime = () => {
  const loggedInTime = localStorage.getItem("loggedInTime");
  if (loggedInTime) return JSON.parse(loggedInTime);
  return null;
};

const isTokenExpired = () => {
  const loggedInTime = getLoggedInTime();
  const currentTime = Date.now();
  const expTime = 1000 * 60 * 30 // 30 minutes
  return loggedInTime && currentTime - loggedInTime > expTime;
}

// is user is logged in
const isUserAuthenticated = () => {
  return isUserLoggedIn() !== null && !isTokenExpired();
};

const login = async () => {

  const currentEmail = localStorage.getItem("currentEmail");
  const currentPassword = localStorage.getItem("currentPassword");
  if (currentEmail && currentPassword) {
    const response = await postLogin({
      email: currentEmail,
      password: currentPassword,
    });

    if (response.token) {
      localStorage.setItem("userAuth", JSON.stringify(response));
      localStorage.setItem("loggedInTime", Date.now());
    } else {
      localStorage.removeItem("userAuth");
      localStorage.removeItem("loggedInTime");
    }
  }
};

export const prepareAuthentications = async () => {
  if (!isUserAuthenticated()) {
    await login();
  }
}