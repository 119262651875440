import React from "react";
import { Navigate } from "react-router-dom";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Location
import LocationList from "../pages/Locations/location-list";
import LocationDetail from "../pages/Locations/location-detail";

// Shipments
import ShipmentList from "../pages/Shipments/shipment-list";
import ShipmentDetail from "../pages/Shipments/shipment-detail";

// Vehicles
import VehicleList from "../pages/Vehicles/vehicle-list";

const authProtectedRoutes = [
  // //dashboard
  { path: "/", component: <Dashboard /> },  

  { path: "/location", component: <LocationList />},
  { path: "/location/detail/:id", component: <LocationDetail />},

  { path: "/shipment", component: <ShipmentList />},
  { path: "/shipment/detail/:id", component: <ShipmentDetail />},

  { path: "/vehicle", component: <VehicleList />},
  // Navigate to location detail page with id


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },

];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
