import React from "react";
import { Link } from "react-router-dom";
import { Row , Col} from "reactstrap";

export const Info = ({ type, title, descp }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="me-4">
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            <i className={`fas fa-${type}`} />
          </span>
        </div>
      </div>
      <div>
        <h5 className="font-size-14 mb-1">{title}</h5>
        <p className="text-muted mb-0">{descp}</p>
      </div>
    </div>
  )
}

export const CollapseButton = ({ isOpen, toggle }) => {
  return (
    <td>
      <Link
        className="text-body fw-medium py-1 d-flex align-items-center"
        onClick={toggle}
        to="#"
      >
        <div className="text-end">
          <i
            className={
              isOpen
                ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                : "mdi mdi-chevron-down accor-down-icon ms-auto"
            }
          />
        </div>
        <div style={{"width":"5px"}}/>


      </Link>

    </td>
  )
}


export const VehicleCapacity = ({ capacity }) => {
  return (
    <td>
      <div className="text-end">
        <h5 className="font-size-14 mb-0">Capacity: {" " + capacity}</h5>
      </div>
    </td>
  )
}
export const DepotColor = "#f1c40f";
export const CustomerColor = "#57b6eb";
export const MapColorPalette = [
  "#57b6eb",
  "#2ecc71",
  "#e74c3c",
  "#3498db",
  "#e74c3c",
  "#9b59b6",
  "#34495e",
  "#e67e22",
  "#95a5a6",
  "#1abc9c",
  "#d35400",
  "#c0392b",
  "#7f8c8d",
  "#2c3e50",
  "#8e44ad"
];
