import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import LocationListSaga from "./location/list/saga";
import LocationDetailSaga from "./location/detail/saga";
import ShipmentListSaga from "./shipment/list/saga";
import ShipmentDetailSaga from "./shipment/detail/saga";
import VehicleListSaga from "./vehicle/list/saga";
import OptimizerSaga from "./optimizer/saga";
import DashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(LocationListSaga),
    fork(LocationDetailSaga),
    fork(ShipmentListSaga),
    fork(ShipmentDetailSaga),
    fork(VehicleListSaga),
    fork(OptimizerSaga),
    fork(DashboardSaga),
  ]);
}
