import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_SHIPMENT_DETAIL,
    UPDATE_SHIPMENT,
    DELETE_SHIPMENT,
    GET_VEHICLE_LIST,
    ASSIGN_VEHICLE
} from "./actionTypes";

import {
    updateShipmentSuccess,
    updateShipmentFail,
    deleteShipmentSuccess,
    deleteShipmentFail,
    getShipmentDetailSuccess,
    getShipmentDetailFail,
    getVehicleListSuccess,
    getVehicleListFail,
    assignVehicleSuccess,
    assignVehicleFail
} from "./actions";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../../helpers/jwt-token-access/accessToken";

import {
    getShipmentDetail,
    putShipment,
    delShipment,
    getVehicleAll,
    postShipmentAssign
} from "../../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchShipmentDetail({ payload: id }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getShipmentDetail, id)
        yield put(getShipmentDetailSuccess(response))
    } catch (error) {
        yield put(getShipmentDetailFail(error))
    }
}

function* onUpdateShipment({ payload: shipment }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(putShipment, shipment)
        yield put(updateShipmentSuccess(response))
        toast.success("Shipment Updated Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(updateShipmentFail(error))
        toast.error(error.response.data.message, { autoClose: 2000 });
    }
}

function* onDeleteShipment({ payload: id }) {
    try {
        yield call(prepareAuthentications);
        yield call(delShipment, id)
        yield put(deleteShipmentSuccess(id))
        toast.success("Shipment Deleted Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(deleteShipmentFail(error))
        toast.error(error.response.data.message, { autoClose: 2000 });
    }
}

function* fetchVehicleList({ payload: { skip, limit } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getVehicleAll, skip, limit)
        if (response.length > 0) {
            yield put(getVehicleListSuccess(response))
        } else {
            yield put(getVehicleListSuccess(["Empty"]))
        }
    } catch (error) {
        yield put(getVehicleListFail(error))
    }
}

function* onAssignShipment({ payload: { vehicleId, shipmentId } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(postShipmentAssign, vehicleId, shipmentId)
        yield put(assignVehicleSuccess(response))
        toast.success("Shipment Assigned Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(assignVehicleFail(error))
        toast.error(error.response.data.message, { autoClose: 2000 });
    }
}

function* ShipmentDetailSaga() {
    yield takeEvery(GET_SHIPMENT_DETAIL, fetchShipmentDetail);
    yield takeEvery(UPDATE_SHIPMENT, onUpdateShipment);
    yield takeEvery(DELETE_SHIPMENT, onDeleteShipment);
    yield takeEvery(GET_VEHICLE_LIST, fetchVehicleList);
    yield takeEvery(ASSIGN_VEHICLE, onAssignShipment);
}

export default ShipmentDetailSaga;