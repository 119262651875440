/* SHIPMENT DETAIL */

export const GET_SHIPMENT_DETAIL = "GET_SHIPMENT_DETAIL"
export const GET_SHIPMENT_DETAIL_SUCCESS = "GET_SHIPMENT_DETAIL_SUCCESS"
export const GET_SHIPMENT_DETAIL_FAIL = "GET_SHIPMENT_DETAIL_FAIL"

/* UPDATE SHIPMENT */

export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT"
export const UPDATE_SHIPMENT_SUCCESS = "UPDATE_SHIPMENT_SUCCESS"
export const UPDATE_SHIPMENT_FAIL = "UPDATE_SHIPMENT_FAIL"

/* DELETE SHIPMENT */

export const DELETE_SHIPMENT = "DELETE_SHIPMENT"
export const DELETE_SHIPMENT_SUCCESS = "DELETE_SHIPMENT_SUCCESS"
export const DELETE_SHIPMENT_FAIL = "DELETE_SHIPMENT_FAIL"

/* VEHICLE LIST */

export const GET_VEHICLE_LIST = "GET_VEHICLE_LIST"
export const GET_VEHICLE_LIST_SUCCESS = "GET_VEHICLE_LIST_SUCCESS"
export const GET_VEHICLE_LIST_FAIL = "GET_VEHICLE_LIST_FAIL"

/* ASSIGN VEHICLE */
export const ASSIGN_VEHICLE = "ASSIGN_VEHICLE"
export const ASSIGN_VEHICLE_SUCCESS = "ASSIGN_VEHICLE_SUCCESS"
export const ASSIGN_VEHICLE_FAIL = "ASSIGN_VEHICLE_FAIL"

/* UNASSIGN VEHICLE */
export const UNASSIGN_VEHICLE = "UNASSIGN_VEHICLE"
export const UNASSIGN_VEHICLE_SUCCESS = "UNASSIGN_VEHICLE_SUCCESS"
export const UNASSIGN_VEHICLE_FAIL = "UNASSIGN_VEHICLE_FAIL"
