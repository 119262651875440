/* ALL LOCATIONS */
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS"
export const GET_ALL_LOCATIONS_SUCCESS = "GET_ALL_LOCATIONS_SUCCESS"
export const GET_ALL_LOCATIONS_FAIL = "GET_ALL_LOCATIONS_FAIL"

/* ADD NEW LOCATION */
export const ADD_LOCATION = "ADD_LOCATION"
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS"
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL"


