import axios from "axios";
import { authHeader } from "./jwt-token-access/auth-token-header";
import fileDownload from "js-file-download";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

const updateHeader = () => {
  const authHeaderData = authHeader();
  for (const key in authHeaderData) {
    try{
      axiosApi.defaults.headers.common[key] = authHeaderData[key];
    }
    catch(err){}
  }
};

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  updateHeader();
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}

export async function post(url, data, config = {}) {
  updateHeader();
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}

export async function put(url, data, config = {}) {
  updateHeader();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}

export async function del(url, config = {}) {
  updateHeader();
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}

export async function download(url, fileName, config = {}) {
  updateHeader();
  return await axiosApi
    .get(url, { ...config, responseType: "blob" })
    .then((response) => fileDownload(response.data, fileName))
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}

export async function upload(url, data, config = {}) {
  updateHeader();
  return await axiosApi
    .post(url, data, { ...config, headers: { "Content-Type": "multipart/form-data" } })
    .then((response) => response.data)
    .catch((err) => {
      throw [err.response.status, err.response.data.message];
    });
}
