import { call, put, takeEvery } from "redux-saga/effects";

import { 
    GET_OPTIMIZER_PAYLOAD,
    OPTIMIZE_ROUTE,
    UPLOAD_OPTIMIZER_TEMPLATE,
    PLAN_OPTIMIZER_ROUTE,
} from "./actionTypes";

import {
    getOptimizerPayloadSuccess,
    getOptimizerPayloadFail,
    optimizeRouteSuccess,
    optimizeRouteFail,
    uploadOptimizerTemplateSuccess,
    uploadOptimizerTemplateFail,
    planOptimizerRouteSuccess,
    planOptimizerRouteFail,
} from "./actions";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../helpers/jwt-token-access/accessToken";

import {
    getOptimizerPayload,
    postDashboardRouteOptimize,
    uploadOptimizerTemplate,
    planOptimizerRoute,
} from "../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchOptimizerPayload({ payload: date }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getOptimizerPayload, date);
        if (response.request.shipments.length === 0) {
            response.request.shipments = ["Empty"];
        }
        if (response.request.vehicles.length === 0) {
            response.request.vehicles = ["Empty"];
        }
        if (response.request.locations.length === 0) {
            response.request.locations = ["Empty"];
        }
        yield put(getOptimizerPayloadSuccess(response));

    } catch (error) {
        yield put(getOptimizerPayloadFail(error));
        console.log(error);
        toast.error("Failed to fetch data", { autoClose: 2000 });
    }
}

function* onOptimizeRoute({ payload: data }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(postDashboardRouteOptimize, data);
        yield put(optimizeRouteSuccess(response));
        toast.success("Route Optimized Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(optimizeRouteFail(error));
        toast.error("Failed to optimize route", { autoClose: 2000 });
    }
}

function* onUploadOptimizerTemplate({ payload: data }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(uploadOptimizerTemplate, data);
        yield put(uploadOptimizerTemplateSuccess(response));
        toast.success("Excel File Uploaded Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(uploadOptimizerTemplateFail(error));
        toast.error("Failed to upload excel file", { autoClose: 2000 });
    }
}

function* onPlanOptimizerRoute({ payload: { date, scenarioId } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(planOptimizerRoute, date, scenarioId);
        yield put(planOptimizerRouteSuccess(response));
        toast.success("Route Planned Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(planOptimizerRouteFail(error));
        toast.error("Failed to plan route", { autoClose: 2000 });
    }
}

function* OptimizerSaga() {
    yield takeEvery(GET_OPTIMIZER_PAYLOAD, fetchOptimizerPayload);
    yield takeEvery(OPTIMIZE_ROUTE, onOptimizeRoute);
    yield takeEvery(UPLOAD_OPTIMIZER_TEMPLATE, onUploadOptimizerTemplate);
    yield takeEvery(PLAN_OPTIMIZER_ROUTE, onPlanOptimizerRoute);
}

export default OptimizerSaga;