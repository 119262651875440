import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postLogin } from "../../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("userAuth", JSON.stringify(response));
      localStorage.setItem("loggedInTime", Date.now());
      localStorage.setItem("currentEmail", user.email);
      localStorage.setItem("currentPassword", user.password);
      yield put(loginSuccess(response));
    history('/');
  } catch (error) {
    yield put(apiError(error));
    toast.error("Email or Password is incorrect", { autoClose: 2000 });
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const lang = localStorage.getItem("I18N_LANGUAGE", "en");
    localStorage.clear();
    localStorage.setItem("I18N_LANGUAGE", lang);
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
