import {
  GET_ALL_LOCATIONS,
  GET_ALL_LOCATIONS_FAIL,
  GET_ALL_LOCATIONS_SUCCESS,
  ADD_LOCATION,
  ADD_LOCATION_FAIL,
  ADD_LOCATION_SUCCESS,
} from "./actionTypes"


// get skip and limit from payload
export const getAllLocations = (skip, limit) => ({
  type: GET_ALL_LOCATIONS,
  payload: { skip, limit }
})

export const getAllLocationsSuccess = locations => ({
  type: GET_ALL_LOCATIONS_SUCCESS,
  payload: locations,
})

export const getAllLocationsFail = error => ({
  type: GET_ALL_LOCATIONS_FAIL,
  payload: error,
})

export const addLocation = location => ({
  type: ADD_LOCATION,
  payload: location,
})

export const addLocationSuccess = location => ({
  type: ADD_LOCATION_SUCCESS,
  payload: location,
})

export const addLocationFail = error => ({
  type: ADD_LOCATION_FAIL,
  payload: error,
})


