import { call, put, takeEvery } from "redux-saga/effects"

import { GET_ALL_LOCATIONS, ADD_LOCATION } from "./actionTypes"

import {
  getAllLocationsSuccess,
  getAllLocationsFail,
  addLocationSuccess,
  addLocationFail,
} from "./actions"

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../../helpers/jwt-token-access/accessToken";

import { getLocationAll, postLocation } from "../../../helpers/backend_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchAllLocations({ payload: { skip, limit } }) {
  try {
    yield call(prepareAuthentications);
    const response = yield call(getLocationAll, skip, limit)
    if (response.length > 0) {
      yield put(getAllLocationsSuccess(response))
    } else {
      yield put(getAllLocationsSuccess(["Empty"]))
    }
  } catch (error) {
    yield put(getAllLocationsFail(error))
  }
}


function* onAddLocation({ payload: location }) {
  try {
    yield call(prepareAuthentications);
    const response = yield call(postLocation, location)
    yield put(addLocationSuccess(response))
    toast.success("Location Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addLocationFail(error))
    toast.error("Location Added Failed", { autoClose: 2000 });
  }
}

function* LocationListSaga() {
  yield takeEvery(GET_ALL_LOCATIONS, fetchAllLocations)
  yield takeEvery(ADD_LOCATION, onAddLocation)
}

export default LocationListSaga;
