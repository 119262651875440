import {
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  GET_LOCATION_DETAIL,
  GET_LOCATION_DETAIL_FAIL,
  GET_LOCATION_DETAIL_SUCCESS,
  GET_LOCATION_SHIPMENTS_DETAIL_FAIL,
  GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  detail: {},
  shipments: [],
  error: {},
  loading: true
}

const detail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATION_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case GET_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_LOCATION_SHIPMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        shipments: action.payload,
      }
    case GET_LOCATION_SHIPMENTS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case UPDATE_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        detail: {},
        loading: false,
      }
    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default detail;


