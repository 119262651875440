import {
  GET_SHIPMENT_DETAIL,
  GET_SHIPMENT_DETAIL_FAIL,
  GET_SHIPMENT_DETAIL_SUCCESS,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_FAIL,
  UPDATE_SHIPMENT_SUCCESS,
  DELETE_SHIPMENT,
  DELETE_SHIPMENT_FAIL,
  DELETE_SHIPMENT_SUCCESS,
  GET_VEHICLE_LIST_SUCCESS,
  GET_VEHICLE_LIST_FAIL,
  ASSIGN_VEHICLE,
  ASSIGN_VEHICLE_SUCCESS,
  ASSIGN_VEHICLE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  detail: {},
  vehicles: [],
  error: {},
  loading: true
}

const detail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHIPMENT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_SHIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case GET_SHIPMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case UPDATE_SHIPMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_SHIPMENT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case DELETE_SHIPMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        loading: false,
      }
    case GET_VEHICLE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ASSIGN_VEHICLE:
      return {
        ...state,
        loading: true,
      }
    case ASSIGN_VEHICLE_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case ASSIGN_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default detail;