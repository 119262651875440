//REGISTER
export const REGISTER = "/user/register";

export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const LOGIN = "/auth/login";

export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const USER = "/user";
export const CURRENT_USER = "/user/current";

export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//LOCATION
export const LOCATION = "/location";
export const LOCATION_ALL = "/location/all";
export const LOCATION_BULK = "/location/bulk";
export const LOCATION_DETAIL = "/location/detail";
export const LOCATION_ACTIVATE = "/location/activate";
export const LOCATION_DEACTIVATE = "/location/deactivate";
export const LOCATION_SHIPMENTS = "/location/shipments";
export const LOCATION_SHIPMENTS_DETAIL = "/location/shipments/detail";

//SHIPMENT
export const SHIPMENT = "/shipment";
export const SHIPMENT_ALL = "/shipment/all";
export const SHIPMENT_BULK = "/shipment/bulk";
export const SHIPMENT_DETAIL = "/shipment/detail";
export const SHIPMENT_ASSIGN = "/shipment/assign";

//VEHICLE
export const VEHICLE = "/vehicle";
export const VEHICLE_ALL = "/vehicle/all";
export const VEHICLE_BULK = "/vehicle/bulk";
export const VEHICLE_DETAIL = "/vehicle/detail";
export const VEHICLE_ACTIVATE = "/vehicle/activate";
export const VEHICLE_DEACTIVATE = "/vehicle/deactivate";
export const VEHICLE_SHIPMENTS = "/vehicle/shipments";
export const VEHICLE_SHIPMENTS_DETAIL = "/vehicle/shipments/detail";

export const DASHBOARD_OPTIMIZER_PAYLOAD = "/optimizer/payload";
export const DASHBOARD_ROUTE_OPTIMIZE = "/optimizer/optimize";
export const DOWNLOD_OPTIMIZER_RESULT = "/documents/spreadsheet/optimizer/download-result";
export const DOWNLOD_OPTIMIZER_TEMPLATE = "/documents/spreadsheet/optimizer/template";
export const UPLOAD_OPTIMIZER_TEMPLATE = "/documents/spreadsheet/optimizer/upload-data";
export const DASHBOARD_WORKLOAD = "/optimizer/workload";
export const DASHBOARD_PLAN_OPTIMIZER_ROUTE = "/scenario/plan";

