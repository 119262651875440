import {
  GET_ALL_VEHICLES_FAIL,
  GET_ALL_VEHICLES_SUCCESS,
  ADD_VEHICLE_FAIL,
  ADD_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  DELETE_VEHICLE_SUCCESS,
  GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS,
  GET_VEHICLE_SHIPMENTS_DETAIL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  all: [],
  shipments: [],
  error: null,
  loading: true
}

const vehicles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false,
      }
    case GET_ALL_VEHICLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        all: state.all[0] !== "Empty" ? [...state.all, action.payload] : [action.payload],
        loading: false,
      }
    case ADD_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        all: state.all.map(vehicle =>
          vehicle.id.toString() === action.payload.id.toString()
            ? { vehicle, ...action.payload }
            : vehicle
        ),
        loading: false,
      }
    case UPDATE_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        all: state.all.length > 1 ? state.all.filter(vehicle => vehicle.id.toString() !== action.payload) : ["Empty"],
        loading: false,
      }
    case DELETE_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_VEHICLE_SHIPMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        shipments: action.payload,
      }
    case GET_VEHICLE_SHIPMENTS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
}

export default vehicles;