import axios from "axios";

export const mapTileLayerUrl = "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";

const googleMapsToken = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const googleMapsSearchAddressUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleMapsToken}&result_type=street_address&region=tr`;

const setAddress = (addressComponents) => {
  const values = addressComponents.reduce((acc, item) => {
    const types = item.types;
    if (types.includes("street_number")) {
      acc.streetNumber = item.long_name;
    }
    if (types.includes("route")) {
      acc.streetName = item.long_name;
    }
    if (types.includes("administrative_area_level_4") || types.includes("neighborhood")) {
      acc.neighborhood = item.long_name;
    }
    if (types.includes("administrative_area_level_2")) {
      acc.city = item.long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      acc.state = item.long_name;
    }
    if (types.includes("postal_code")) {
      acc.zipCode = item.long_name;
    }
    if (types.includes("country")) {
      acc.country = item.long_name;
    }
    return acc;
  }, {});

  return values;
}

export const searchReverseAddress = async (coordinates) => {
  const url = `${googleMapsSearchAddressUrl}&latlng=${coordinates[0]},${coordinates[1]}`;
  const response = await axios.post(url);
  if (response.data.status !== "OK") {
    return {
      address: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
    }
  }

  const addressComponents = response.data.results[0].address_components;
  const values = setAddress(addressComponents);
  const address = [values.neighborhood, values.streetName, values.streetNumber].filter(Boolean).join(", ");

  return {
    address: address,
    city: values.city,
    state: values.state,
    zipCode: values.zipCode,
    country: values.country,
  }
}

export const searchAddress = async (address) => {
  if (!address) {
    return [];
  }
  
  const url = `${googleMapsSearchAddressUrl}&address=${address}`;
  const response = await axios.post(url);
  if (response.data.status !== "OK") {
    return []
  }

  const results = response.data.results.map((item) => {
    
    const addressComponents = item.address_components;
    const values = setAddress(addressComponents);
    const address = [values.neighborhood, values.streetName, values.streetNumber].filter(Boolean).join(", ");

    return {
      address: address,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      country: values.country,
      latitude: item.geometry.location.lat,
      longitude: item.geometry.location.lng,
      formattedAddress: item.formatted_address,
    }
  });
  return results;
}






