import { del, get, post, put, download, upload } from "./api_helper";
import * as url from "./url_helper";

// Login Method
export const postLogin = data => post(url.LOGIN, data);

// Current User
export const getCurrentUser = () => get(url.CURRENT_USER);

// Location
export const getLocation = id => get(url.LOCATION + "?id=" + id);
export const postLocation = data => post(url.LOCATION, data);
export const putLocation = data => put(url.LOCATION, data);
export const delLocation = id => del(url.LOCATION + "?id=" + id);
export const getLocationAll = (skip, limit) => get(url.LOCATION_ALL + "?skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const getLocationDetail = id => get(url.LOCATION_DETAIL + "?id=" + id);
export const getLocationShipments = (id, skip, limit) => get(url.LOCATION_SHIPMENTS + "?id=" + id + "&skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const getLocationShipmentsDetail = (id, skip, limit) => get(url.LOCATION_SHIPMENTS_DETAIL + "?id=" + id + "&skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const postLocationBulk = data => post(url.LOCATION_BULK, data);
export const postLocationActivate = id => put(url.LOCATION_ACTIVATE + "?id=" + id);
export const postLocationDeactivate = id => put(url.LOCATION_DEACTIVATE + "?id=" + id);

// Shipment
export const getShipment = id => get(url.SHIPMENT + "?id=" + id);
export const postShipment = data => post(url.SHIPMENT, data);
export const putShipment = data => put(url.SHIPMENT, data);
export const delShipment = id => del(url.SHIPMENT + "?id=" + id);
export const getShipmentAll = (skip, limit) => get(url.SHIPMENT_ALL + "?skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const getShipmentDetail = id => get(url.SHIPMENT_DETAIL + "?id=" + id);
export const postShipmentAssign = (vehicleId, shipmentId) => post(url.SHIPMENT_ASSIGN + "?vehicle_id=" + vehicleId + "&shipment_id=" + shipmentId);
export const postShipmentBulk = data => post(url.SHIPMENT_BULK, data);

// Vehicle
export const getVehicle = id => get(url.VEHICLE + "?id=" + id);
export const postVehicle = data => post(url.VEHICLE, data);
export const putVehicle = data => put(url.VEHICLE, data);
export const delVehicle = id => del(url.VEHICLE + "?id=" + id);
export const getVehicleAll = (skip, limit) => get(url.VEHICLE_ALL + "?skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const getVehicleDetail = id => get(url.VEHICLE_DETAIL + "?id=" + id);
export const getVehicleShipments = (id, skip, limit) => get(url.VEHICLE_SHIPMENTS + "?id=" + id + "&skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const getVehicleShipmentsDetail = (id, skip, limit) => get(url.VEHICLE_SHIPMENTS_DETAIL + "?id=" + id + "&skip=" + (skip ?? 0) + "&limit=" + (limit ?? 100));
export const postVehicleBulk = data => post(url.VEHICLE_BULK, data);
export const postVehicleActivate = id => put(url.VEHICLE_ACTIVATE + "?id=" + id);
export const postVehicleDeactivate = id => put(url.VEHICLE_DEACTIVATE + "?id=" + id);

// Dashboard
export const getOptimizerPayload = date => get(url.DASHBOARD_OPTIMIZER_PAYLOAD + "?date=" + date);
export const postDashboardRouteOptimize = data => post(url.DASHBOARD_ROUTE_OPTIMIZE, data);
export const donwloadOptimizerResult = (scenarioId, fileName) => download(url.DOWNLOD_OPTIMIZER_RESULT + "?scenarioId=" + scenarioId, fileName);
export const donwloadOptimizerTemplate = fileName => download(url.DOWNLOD_OPTIMIZER_TEMPLATE, fileName);
export const uploadOptimizerTemplate = (data) => upload(url.UPLOAD_OPTIMIZER_TEMPLATE, data);
export const getOptimizerWorkload = (date, limit = 5) => get(url.DASHBOARD_WORKLOAD + "?date=" + date + "&limit=" + limit);
export const planOptimizerRoute = (date, scenarioId) => post(url.DASHBOARD_PLAN_OPTIMIZER_ROUTE + "?date=" + date + "&scenarioId=" + scenarioId);
