import {
  GET_ALL_SHIPMENTS_FAIL,
  GET_ALL_SHIPMENTS_SUCCESS,
  ADD_SHIPMENT_FAIL,
  ADD_SHIPMENT_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  all: [],
  error: null,
  loading: true
}

const shipments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SHIPMENTS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false,
      }
    case GET_ALL_SHIPMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_SHIPMENT_SUCCESS:
      return {
        ...state,
        all: state.all[0] !== "Empty" ? [...state.all, action.payload] : [action.payload],
        loading: false,
      }
    case ADD_SHIPMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}

export default shipments;