import {
    GET_OPTIMIZER_PAYLOAD_FAIL,
    GET_OPTIMIZER_PAYLOAD_SUCCESS,
    OPTIMIZE_ROUTE_FAIL,
    OPTIMIZE_ROUTE_SUCCESS,
    UPLOAD_OPTIMIZER_TEMPLATE_FAIL,
    UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS,
    PLAN_OPTIMIZER_ROUTE_SUCCESS,
    PLAN_OPTIMIZER_ROUTE_FAIL,
    RESET_OPTIMIZER_DATA
} from './actionTypes';

const INIT_STATE = {
    data: {
        request: {
            shipments: [],
            vehicles: [],
            locations: [],
            currentDate: null,
        },
        response: null,
    },
    scenarioIds: [],
    request: {
        shipments: [],
        vehicles: [],
        locations: [],
        currentDate: null,
    },
    response: null,
    error: null,
    isPlanned: false,
    loading: true
}

const optimizer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OPTIMIZER_PAYLOAD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                request: action.payload.request,
                response: action.payload.response,
                isPlanned: action.payload.response ? true : false,
                loading: false,
            }
        case GET_OPTIMIZER_PAYLOAD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case OPTIMIZE_ROUTE_SUCCESS:
            return {
                ...state,
                response: action.payload,
                isPlanned: false,
                loading: false,
            }
        case OPTIMIZE_ROUTE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case UPLOAD_OPTIMIZER_TEMPLATE_SUCCESS:
            return {
                ...state,
                scenarioIds: action.payload,
            }
        case UPLOAD_OPTIMIZER_TEMPLATE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case PLAN_OPTIMIZER_ROUTE_SUCCESS:
            return {
                ...state,
                isPlanned: true,
                loading: false,
            }
        case PLAN_OPTIMIZER_ROUTE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case RESET_OPTIMIZER_DATA:
            return {
                ...state,
                data: {
                    request: {
                        shipments: [],
                        vehicles: [],
                        locations: [],
                        currentDate: null,
                    },
                    response: null,
                },
                scenarioIds: [],
                request: {
                    shipments: [],
                    vehicles: [],
                    locations: [],
                    currentDate: null,
                },
                response: null,
                error: null,
                loading: true
            }
        default:
            return state;
    }
}

export default optimizer;