import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import Spinners from "components/Common/Spinner";

import {
  getLocationDetail as onGetLocationDetail,
  getLocationShipmentsDetail as onGetLocationShipmentsDetail,
  deleteLocation as onDeleteLocation,
  updateLocation as onUpdateLocation,
} from "store/location/detail/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";

// Map
import { TileLayer, Marker, Popup, MapContainer, useMap } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import { mapTileLayerUrl } from "constants/map";
import { renderToStaticMarkup } from "react-dom/server";
import { searchReverseAddress, searchAddress } from "constants/map";


import { locationStatuses } from "constants/status";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const LocationDetail = props => {
  document.title = "Flio.ai";

  const locationId = props.router.params.id;

  const dispatch = useDispatch();
  const selectLocationDetailState = state => state.locationDetail;
  const LocationDetailProperties = createSelector(
    selectLocationDetailState,
    stateProperty => ({
      loading: stateProperty.loading,
      detail: stateProperty.detail,
      error: stateProperty.error,
      shipments: stateProperty.shipments,
    })
  );

  const { loading, detail, error, shipments } = useSelector(LocationDetailProperties);

  const [isLoading, setLoading] = useState(loading);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchAddressModal, setSearchAddressModal] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [lat, setLat] = useState(41.015137);
  const [lng, setLng] = useState(28.979530);
  const mapRef = useRef(null);

  useEffect(() => {
    setLoading(true);
  }, [locationId]);

  const fetchLocationShipments = (skip, limit) => {
    dispatch(onGetLocationShipmentsDetail(locationId, skip, limit));
  }

  const onClickDelete = () => {
    setDeleteModal(true);
  }

  const actionToggle = () => {
    setModal(!modal);
  };


  const handleDeletejob = () => {
    setLoading(true);
    dispatch(onDeleteLocation(locationId));
    props.router.navigate(-1);
  }

  useEffect(() => {
    if (detail) {
      setLoading(true);
      dispatch(onGetLocationDetail(locationId));
      fetchLocationShipments(0, 100);
      setLat(detail.latitude);
      setLng(detail.longitude);
    }
  }, [dispatch, locationId]);

  useEffect(() => {
    if (shipments && shipments.length > 0) {
      setHistoryLoading(false);
    }
  }, [shipments]);

  const mapIcon = new L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    html: renderToStaticMarkup(
      <div className='leaflet-pin' style={{ backgroundColor: "#57b6eb" }}>
        <span>{"●"}</span>
      </div>
    )
  });

  const MapComponent = () => {
    const map = useMap();
    var button = L.control({ position: 'topright' });

    useEffect(() => {
      button.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
        div.innerHTML = `
        <button style="width: 30px; height: 30px; border-radius: 4px; 
                        border: 2px solid rgba(0,0,0,0.2); background-color: #ffffff; 
                        display: flex; justify-content: center; 
                        align-items: center; font-size: 16px; font-weight: 600; cursor: pointer;">
          <i class="mdi mdi-map-search-outline font-size-18" style="color: #000000;"></i>
        </button>
      `;

        div.onclick = function (e) {
          e.preventDefault();
          e.stopPropagation();
          setSearchAddressModal(true);
        };
        return div;
      }
      button.addTo(map);
      return () => {
        map.removeControl(button);
      }
    }, [map]);
    return null;
  };

  const searchReverse = async (lat, lng) => {
    validation.setFieldValue('latitude', lat.toFixed(6));
    validation.setFieldValue('longitude', lng.toFixed(6));

    const result = await searchReverseAddress([lat, lng]);
    validation.setFieldValue('address', result.address);
    validation.setFieldValue('city', result.city);
    validation.setFieldValue('state', result.state);
    validation.setFieldValue('zipCode', result.zipCode);
    validation.setFieldValue('country', result.country);
  }

  const search = async (address) => {
    // Clear the existing timeout if it's set
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Add a delay of 1000 milliseconds (1 second)
    const delay = 1000;

    // Set a new timeout and update the searchTimeout state
    const timeoutId = setTimeout(async () => {
      const result = await searchAddress(address);
      if (!result.length) {
        setAddressNotFound(true);
      } else {
        setAddressNotFound(false);
      }
      setAddresses(result);
    }, delay);

    setSearchTimeout(timeoutId);
  };

  const setAddress = (address) => {
    validation.setFieldValue('address', address.address);
    validation.setFieldValue('city', address.city);
    validation.setFieldValue('state', address.state);
    validation.setFieldValue('zipCode', address.zipCode);
    validation.setFieldValue('country', address.country);
    validation.setFieldValue('latitude', address.latitude);
    validation.setFieldValue('longitude', address.longitude);
    setLat(address.latitude);
    setLng(address.longitude);
    setAddresses([]);

    // CENTER MAP
    mapRef.current.setView([address.latitude, address.longitude], 15);

  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (detail && detail.name) || "",
      latitude: (detail && detail.latitude) || "",
      longitude: (detail && detail.longitude) || "",
      shiftStart: (detail && detail.shiftStart) || "",
      shiftEnd: (detail && detail.shiftEnd) || "",
      address: (detail && detail.address) || "",
      city: (detail && detail.city) || "",
      state: (detail && detail.state) || "",
      zipCode: (detail && detail.zipCode) || "",
      country: (detail && detail.country) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Location-Name-Rq")),
      latitude: Yup.number().required(props.t("Location-Latitude-Rq")),
      longitude: Yup.number().required(props.t("Location-Longitude-Rq")),
      shiftStart: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, props.t("Invalid-Time")),
      shiftEnd: Yup.string().matches(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, props.t("Invalid-Time")),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      country: Yup.string()
    }),
    onSubmit: values => {
      const data = {
        id: locationId,
        name: values.name,
        latitude: values.latitude,
        longitude: values.longitude,
        shiftStart: values.shiftStart,
        shiftEnd: values.shiftEnd,
        address: values.address,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        country: values.country,
        userId: detail.userId,
        status: detail.status,
        createdAt: detail.createdAt,
        updatedAt: detail.updatedAt,
      }
      dispatch(onUpdateLocation(data));

      validation.resetForm();
      actionToggle();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: " ",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              <i className="fas fa-box" />
            </span>
          </div>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: 'name',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Pickup"),
        filterable: true,
        Cell: cellProps => {
          const pickupId = cellProps.row.original.pickupId;
          const isSameId = pickupId && (pickupId == locationId);
          const pickupName = cellProps.row.original.pickupName;
          return isSameId ? pickupName ?? "-"
            :
            <Link to={`/location/detail/${pickupId}`}
              style={{ color: 'inherit' }}>
              {pickupName ?? "-"}
            </Link>
        },
      },
      {
        Header: props.t("Delivery"),
        filterable: true,
        Cell: cellProps => {
          const deliveryId = cellProps.row.original.deliveryId;
          const isSameId = deliveryId && (deliveryId == locationId);
          const deliveryName = cellProps.row.original.deliveryName;
          return isSameId ? deliveryName ?? "-"
            :
            <Link to={`/location/detail/${deliveryId}`}
              style={{ color: 'inherit' }}>

              {deliveryName ?? "-"}
            </Link>
        },
      },
      {
        Header: props.t("Vehicle"),
        accessor: 'vehicleName',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? props.t("No-Vehicles-Found");
        }
      },
      {
        Header: props.t("Amount"),
        accessor: 'amount',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Shipment")} ${props.t("Date")}`,
        accessor: 'shipmentDate',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Pickup")} ${props.t("Start-Time")}`,
        accessor: 'startTime',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: `${props.t("Delivery")} ${props.t("End-Time")}`,
        accessor: 'endTime',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: props.t("Setup-Duration"),
        accessor: 'setupDuration',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: props.t("Service-Duration"),
        accessor: 'serviceDuration',
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: " ",
        accessor: "id",
        filterable: true,
        Cell: cellProps =>
          <Link to={`/shipment/detail/${cellProps.value}`}
            className="btn btn-sm btn-soft-primary"><i className="mdi mdi-eye-outline"></i>
          </Link>


      },
    ],
    []
  );


  const LocationActiveShipments = () =>
    <Col lg={12}>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{props.t("Location-Active-Shipments")}</CardTitle>
          <div className="table-responsive">
            {historyLoading ? <Spinners setLoading={setHistoryLoading} />
              : shipments[0] === "Empty" ?
                <Container fluid className="text-center d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                  <h5>{props.t("No-Active-Found")}</h5>
                </Container>
                :
                <TableContainer
                  columns={columns}
                  data={shipments}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isPagination={true}
                  iscustomPageSizeOptions={false}
                  isShowingPageLength={true}
                  customPageSize={5}
                  tableClass="table align-middle nowrap mt-2"
                  theadClass="table-light"
                  paginationDiv="col-sm-12 col-md-7"
                  pagination="pagination justify-content-end pagination-rounded"
                />
            }
          </div>
        </CardBody>
      </Card>
    </Col>


  return (
    <React.Fragment>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
        message={props.t("Delete-Location-Message")}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Locations")} breadcrumbItem={props.t("Location-Detail")} />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Container fluid>
                <Modal isOpen={searchAddressModal} toggle={() => setSearchAddressModal(!searchAddressModal)} size="md">
                  <ModalHeader toggle={() => setSearchAddressModal(!searchAddressModal)} tag="h4">
                    {props.t("Search-Address")}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onChange={(e) => {
                        search(e.target.value);
                      }}
                    >
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            <Row className="mb-3">
                              <Col>
                                <Label className="form-label">{props.t("Address")}</Label>
                                <Input
                                  name="address"
                                  label="address"
                                  placeholder={props.t("Address-Plc")}
                                  type="text"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                    <div className="mt-3">
                      <ul className="list-group">
                        {!addressNotFound ? addresses.map((item, index) => {
                          return <li className="list-group-item" key={index} onClick={() => {
                            setAddress(item);
                            setSearchAddressModal(false);
                          }}>
                            {item.formattedAddress}
                          </li>
                        }) :
                          <li className="list-group-item">
                            {props.t("No-Result-Found")}
                          </li>
                        }
                      </ul>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal isOpen={modal} toggle={actionToggle} size="xl" centered>
                  <ModalHeader toggle={actionToggle} tag="h4">
                    {props.t("Add-Location")}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Container fluid>
                        <Row>
                          <Col lg={6}>
                            <Row className="mb-3">
                              <Col>
                                <Label className="form-label">{props.t("Name")}</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Enter Location Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                      validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                  validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="d-flex">
                              <Col className="mb-3">
                                <Label className="form-label">{props.t("Latitude")}</Label>
                                <Input
                                  name="latitude"
                                  type="text"
                                  placeholder="41.015137"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.latitude || ""}
                                  invalid={
                                    validation.touched.latitude &&
                                      validation.errors.latitude
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              <Col className="ms-3 mb-3">
                                <Label className="form-label">{props.t("Longitude")}</Label>
                                <Input
                                  name="longitude"
                                  type="text"
                                  placeholder="28.979530"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.longitude || ""}
                                  invalid={
                                    validation.touched.longitude &&
                                      validation.errors.longitude
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex">
                              <Col className="mb-3">
                                <Label className="form-label">{props.t("Shift-Start")}</Label>
                                <Input
                                  name="shiftStart"
                                  type="text"
                                  placeholder="HH:MM:SS"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.shiftStart || ""}
                                  invalid={
                                    validation.touched.shiftStart &&
                                      validation.errors.shiftStart
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              <Col className="ms-3 mb-3">
                                <Label className="form-label">{props.t("Shift-End")}</Label>
                                <Input
                                  name="shiftEnd"
                                  type="text"
                                  placeholder="HH:MM:SS"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.shiftEnd || ""}
                                  invalid={
                                    validation.touched.shiftEnd &&
                                      validation.errors.shiftEnd
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <Label className="form-label">{props.t("Address")}</Label>
                                <Input
                                  name="address"
                                  label="address"
                                  placeholder="Enter Address"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                      validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex">
                              <Col className="mb-3">
                                <Label className="form-label">{props.t("City")}</Label>
                                <Input
                                  name="city"
                                  type="text"
                                  placeholder="Enter City"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.city || ""}
                                  invalid={
                                    validation.touched.city &&
                                      validation.errors.city
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              <Col className="ms-3 mb-3">
                                <Label className="form-label">{props.t("State")}</Label>
                                <Input
                                  name="state"
                                  type="text"
                                  placeholder="Enter State"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.state || ""}
                                  invalid={
                                    validation.touched.state &&
                                      validation.errors.state
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="d-flex">
                              <Col className="mb-3">
                                <Label className="form-label">{props.t("Zip")}</Label>
                                <Input
                                  name="zipCode"
                                  type="text"
                                  placeholder="Enter Zip Code"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.zipCode || ""}
                                  invalid={
                                    validation.touched.zipCode &&
                                      validation.errors.zipCode
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              <Col className="ms-3 mb-3">
                                <Label className="form-label">{props.t("Country")}</Label>
                                <Input
                                  name="country"
                                  type="text"
                                  placeholder="Enter Country"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.country || ""}
                                  invalid={
                                    validation.touched.country &&
                                      validation.errors.country
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <MapContainer center={[lat ?? detail.latitude, lng ?? detail.longitude]} zoom={15} scrollWheelZoom={false}
                              style={{ width: "100%", height: "100%" }} ref={mapRef}>
                              <MapComponent />
                              <TileLayer
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                url={mapTileLayerUrl}
                              />
                              <Marker position={[lat ?? detail.latitude, lng ?? detail.longitude]} draggable={true} animate={true}
                                icon={mapIcon} eventHandlers={{
                                  dragend: (e) => {
                                    setLat(e.target._latlng.lat);
                                    setLng(e.target._latlng.lng);

                                    searchReverse(e.target._latlng.lat, e.target._latlng.lng);
                                  }
                                }
                                }>
                              </Marker>
                            </MapContainer>
                          </Col>
                        </Row>
                        <Col className="text-end mt-3">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            {props.t("Save")}
                          </button>
                        </Col>
                      </Container>
                    </Form>
                  </ModalBody>
                </Modal>
                <Row>
                  <Col lg={6}>
                    <Card>
                      {/* position: "relative", height: "100%" */}
                      <CardBody className="p-10 m-0">
                        <MapContainer center={[detail?.latitude || 0, detail?.longitude || 0]} zoom={15} scrollWheelZoom={false}
                          style={{ width: "100%", height: "40vh" }}>
                          <TileLayer
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            url={mapTileLayerUrl}
                          />
                          <Marker position={[detail?.latitude || 0, detail?.longitude || 0]} icon={mapIcon}>
                            <Popup>
                              {detail.name}
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </CardBody>

                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card>
                      <CardBody>
                        {/** give space table and buttons */}
                        <div className="d-flex justify-content-between flex-column"
                          style={{ height: "40vh" }}>
                          <div style={{ overflowX: "auto" }}>
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{props.t("Name")} :</th>
                                  <td>
                                    {detail.name + " "}
                                    <span className={`badge badge-pill ${locationStatuses[detail.status].style}`}>
                                      {locationStatuses[detail.status].text}
                                    </span>
                                  </td>
                                  <th /><td />
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Latitude")} :</th>
                                  <td>{detail.latitude}</td>
                                  <th scope="row">{props.t("Longitude")} :</th>
                                  <td>{detail.longitude}</td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Shift-Start")} :</th>
                                  <td>{detail.shiftStart}</td>
                                  <th scope="row">{props.t("Shift-End")} :</th>
                                  <td>{detail.shiftEnd}</td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Address")} :</th>
                                  <td>{detail.address}</td>
                                  <th /><td />
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("City")} :</th>
                                  <td>{detail.city}</td>
                                  <th scope="row">{props.t("State")} :</th>
                                  <td>{detail.state}</td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Zip")} :</th>
                                  <td>{detail.zipCode}</td>
                                  <th scope="row">{props.t("Country")} :</th>
                                  <td>{detail.country}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          <Row className="d-flex justify-content-end m-0">
                            <Col className="mt-3" lg={6} style={{ width: "10rem" }}>
                              <Link className="btn btn-soft-primary align-items-center d-flex justify-content-center"
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => actionToggle()}>{props.t("Edit-Location")}
                              </Link>
                            </Col>
                            <Col className="mt-3" lg={6} style={{ width: "10rem" }}>
                              <Link className="btn btn-soft-danger align-items-center d-flex justify-content-center"
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => onClickDelete()}>{props.t("Delete-Location")}
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <LocationActiveShipments />
              </Container>
          }
        </Container>
      </div>
    </React.Fragment>
  );
}


LocationDetail.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(LocationDetail));