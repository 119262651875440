import {
    GET_SHIPMENT_DETAIL,
    GET_SHIPMENT_DETAIL_FAIL,
    GET_SHIPMENT_DETAIL_SUCCESS,
    UPDATE_SHIPMENT,
    UPDATE_SHIPMENT_FAIL,
    UPDATE_SHIPMENT_SUCCESS,
    DELETE_SHIPMENT,
    DELETE_SHIPMENT_FAIL,
    DELETE_SHIPMENT_SUCCESS,
    GET_VEHICLE_LIST,
    GET_VEHICLE_LIST_FAIL,
    GET_VEHICLE_LIST_SUCCESS,
    ASSIGN_VEHICLE,
    ASSIGN_VEHICLE_FAIL,
    ASSIGN_VEHICLE_SUCCESS,
} from "./actionTypes";

export const getShipmentDetail = id => ({
    type: GET_SHIPMENT_DETAIL,
    payload: id,
})

export const getShipmentDetailSuccess = shipmentDetail => ({
    type: GET_SHIPMENT_DETAIL_SUCCESS,
    payload: shipmentDetail,
})

export const getShipmentDetailFail = error => ({
    type: GET_SHIPMENT_DETAIL_FAIL,
    payload: error,
});

export const updateShipment = shipment => ({
    type: UPDATE_SHIPMENT,
    payload: shipment,
})

export const updateShipmentSuccess = shipment => ({
    type: UPDATE_SHIPMENT_SUCCESS,
    payload: shipment,
})

export const updateShipmentFail = error => ({
    type: UPDATE_SHIPMENT_FAIL,
    payload: error,
})

export const deleteShipment = id => ({
    type: DELETE_SHIPMENT,
    payload: id,
})

export const deleteShipmentSuccess = () => ({
    type: DELETE_SHIPMENT_SUCCESS,
})

export const deleteShipmentFail = error => ({
    type: DELETE_SHIPMENT_FAIL,
    payload: error,
})

export const getVehicleList = (skip, limit) => ({
    type: GET_VEHICLE_LIST,
    payload: { skip, limit }
})

export const getVehicleListSuccess = vehicles => ({
    type: GET_VEHICLE_LIST_SUCCESS,
    payload: vehicles,
})

export const getVehicleListFail = error => ({
    type: GET_VEHICLE_LIST_FAIL,
    payload: error,
})

export const assignVehicle = (shipmentId, vehicleId) => ({
    type: ASSIGN_VEHICLE,
    payload: { shipmentId, vehicleId }
})

export const assignVehicleSuccess = shipmentDetail => ({
    type: ASSIGN_VEHICLE_SUCCESS,
    payload: shipmentDetail,
})

export const assignVehicleFail = error => ({
    type: ASSIGN_VEHICLE_FAIL,
    payload: error,
})


