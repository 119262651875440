export const vehicleStatuses = {
  100: {
    style: "badge-soft-success",
    text: "Free"
  },
  200: {
    style: "badge-soft-primary",
    text: "Working"
  },
  300: {
    style: "badge-soft-danger",
    text: "Busy"
  },
  400: {
    style: "badge-soft-secondary",
    text: "Inactive"
  },
}

export const locationStatuses = {
  100: {
    style: "badge-soft-success",
    text: "Active"
  },
  200: {
    style: "badge-soft-secondary",
    text: "Inactive"
  },
}

export const shipmentStatuses = {
  100: {
    style: "badge-soft-success",
    text: "Active"
  },
  200: {
    style: "badge-soft-primary",
    text: "Planned"
  },
  300: {
    style: "badge-soft-warning",
    text: "On Delivery"
  },
  400: {
    style: "badge-soft-info",
    text: "Delivered"
  },
  500: {
    style: "badge-soft-danger",
    text: "Cancelled"
  },
}