import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import Spinners from "components/Common/Spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteModal from "components/Common/DeleteModal";
import { mapTileLayerUrl } from "constants/map";
import { renderToStaticMarkup } from "react-dom/server";

import {
  getShipmentDetail as onGetShipmentDetail,
  getVehicleList as onGetVehicleList,
  assignVehicle as onAssignVehicle,
  updateShipment as onUpdateShipment,
  deleteShipment as onDeleteShipment,
} from "store/shipment/detail/actions";
import { getLocationAll, getVehicleAll } from "helpers/backend_helper";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

// Map
import { TileLayer, Marker, Popup, MapContainer, useMap } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import TableContainer from "components/Common/TableContainer";
import { ToastContainer } from "react-toastify";
import { locationStatuses, shipmentStatuses, vehicleStatuses } from "constants/status";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const ShipmentDetail = props => {
  document.title = "Flio.ai";

  const shipmentId = props.router.params.id;

  const dispatch = useDispatch();

  const selectShipmentDetailState = state => state.shipmentDetail;
  const ShipmentDetailProperties = createSelector(
    selectShipmentDetailState,
    stateProperty => ({
      detail: stateProperty.detail,
      vehicles: stateProperty.vehicles,
      loading: stateProperty.loading,
      error: stateProperty.error,
    })
  );

  const { loading, vehicles, detail, error } = useSelector(ShipmentDetailProperties);

  const [isLoading, setLoading] = useState(loading);
  const [mapBounds, setMapBounds] = useState(null);
  const [isMapInit, setMapInit] = useState(false);
  const [modal, setModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isVehicleLoading, setVehicleLoading] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    setMapBounds(null);
    setMapInit(false);
  }, [shipmentId]);

  useEffect(() => {
    if (detail) {
      setLoading(true);
      dispatch(onGetShipmentDetail(shipmentId));
    }
  }, [shipmentId, dispatch]);

  useEffect(() => {
    if (vehicles && vehicles.length) {
      setVehicleLoading(false);
    }
  }, [vehicles]);

  useEffect(() => {
    if (vehicleModal && !detail.vehicleId && !vehicles.length) {
      setVehicleLoading(true);
      dispatch(onGetVehicleList(0, 100));
    }
  }, [vehicleModal]);

  useEffect(() => {
    if (modal && !locationOptions.length) {
      setTimeout(async () => {
        const response = await getLocationAll(0, 100);
        const options = response.map(item => ({
          value: item.id,
          label: item.name
        }));
        setLocationOptions(options);
      }, 1000); // Simulating delay for demonstration purposes
    }
  }, [modal]);

  useEffect(() => {
    if (modal && !vehicleOptions.length) {
      setTimeout(async () => {
        const response = await getVehicleAll(0, 100);
        const options = response.map(item => ({
          value: item.id,
          label: item.name
        }));
        setVehicleOptions(options);
      }, 1000); // Simulating delay for demonstration purposes
    }
  }, [modal]);

  useEffect(() => {
    if (!isLoading && !isMapInit) {
      const pickupLatLng = L.latLng(detail.pickup.latitude, detail.pickup.longitude);
      const deliveryLatLng = L.latLng(detail.delivery.latitude, detail.delivery.longitude);
      const bounds = L.latLngBounds(pickupLatLng, deliveryLatLng);
      setMapBounds(bounds);
    }
  }, [isLoading, isMapInit]);

  const onClickAssignVehicle = (vehicleData) => {
    const shipmentId = detail.id;
    const vehicleId = vehicleData.id;
    if (shipmentId && vehicleId) {
      dispatch(onAssignVehicle(shipmentId, vehicleId));
      setVehicleModal(false);
    }
  }

  const handleDeletejob = () => {
    setLoading(true);
    dispatch(onDeleteShipment(shipmentId));
    props.router.navigate(-1);
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (detail && detail.name) || "",
      amount: (detail && detail.amount) || "",
      shipmentDate: (detail && detail.shipmentDate) || "",
      pickupId: (detail && detail.pickupId) || "",
      deliveryId: (detail && detail.deliveryId) || "",
      vehicleId: (detail && detail.vehicleId) || "",
      setupDuration: (detail && detail.setupDuration) || "",
      serviceDuration: (detail && detail.serviceDuration) || "",
      startTime: (detail && detail.startTime) || "",
      endTime: (detail && detail.endTime) || "",
      priority: (detail && detail.priority) || "",
      notes: (detail && detail.notes) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Shipment-Name-Rq")),
      amount: Yup.number().required(props.t("Amount-Rq")),
      shipmentDate: Yup.date().required(props.t("Shipment-Date-Rq")),
      pickupId: Yup.string().required(props.t("Pickup-Location-Rq")),
      deliveryId: Yup.string().required(props.t("Delivery-Location-Rq")),
      vehicleId: Yup.string(),
      setupDuration: Yup.number().default(0),
      serviceDuration: Yup.number().default(0),
      startTime: Yup.string(),
      endTime: Yup.string(),
      priority: Yup.string().default("medium").required(props.t("Select-Priority")),
      notes: Yup.string(),
    }),
    onSubmit: values => {
      const data = {
        id: detail.id,
        name: values.name,
        amount: values.amount,
        shipmentDate: values.shipmentDate,
        pickupId: values.pickupId,
        deliveryId: values.deliveryId,
        vehicleId: values.vehicleId || null,
        setupDuration: values.setupDuration || 0,
        serviceDuration: values.serviceDuration || 0,
        startTime: values.startTime || null,
        endTime: values.endTime || null,
        priority: values.priority,
        notes: values.notes || null,
        userId: detail.userId,
        createdAt: detail.createdAt,
        updatedAt: detail.updatedAt,
        status: detail.status,
      }
      dispatch(onUpdateShipment(data));
      toggle();
    },
  });

  const vehicleColumns = useMemo(
    () => [
      {
        Header: " ",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              <i className="fas fa-truck" />
            </span>
          </div>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        },
      },
      {
        Header: props.t("Type"),
        accessor: "type",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Plate"),
        accessor: "plate",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Person"),
        accessor: "person",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? "-";
        }
      },
      {
        Header: props.t("Capacity"),
        accessor: 'capacity',
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        }
      },
      {
        Header: props.t("Shift-Start"),
        accessor: "shiftStart",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: props.t("Shift-End"),
        accessor: "shiftEnd",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value ?? '-';
        },
      },
      {
        Header: " ",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <span className={`badge badge-pill ${vehicleStatuses[cellProps.value].style}`}>
            {vehicleStatuses[cellProps.value].text}
          </span>
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          return (
            <Link
              className="btn btn-sm btn-soft-primary"
              onClick={() => {
                const vehicleData = cellProps.row.original;
                onClickAssignVehicle(vehicleData);
              }}
            >
              <i className="mdi mdi-car-cog" id="viewtooltip"></i>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                {props.t("Assign-Vehicle")}
              </UncontrolledTooltip>
            </Link>
          );
        },
      },
    ],
    []
  );

  const mapIcon = new L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    html: renderToStaticMarkup(
      <div className='leaflet-pin' style={{ backgroundColor: "#57b6eb" }}>
        <span>{"●"}</span>
      </div>
    )
  });

  const MapComponent = () => {
    function calculateAndSetZoom() {
      if (mapBounds && !isMapInit) {
        map.fitBounds(mapBounds);
        map.setZoom(map.getZoom() - 1);
        setMapInit(true);
      }
    }

    const map = useMap();
    calculateAndSetZoom();
    return null;
  };

  const setLocationAddress = (fields) => {
    let address = "";
    for (const field of fields) {
      if (field) {
        address += field + ", ";
      }
    }
    return address[address.length - 2] === "," ? address.slice(0, -2) : address;
  }

  const vehicleToggle = () => {
    setVehicleModal(!vehicleModal);
  }

  const toggle = () => {
    setModal(!modal);
  }

  const ShipmentDetailComponentTitle = ({ title, link, isVehicle }) =>
    <Container style={{ marginBottom: "3vh" }}>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0 card-title flex-grow-1">{title}</h5>
        <div className="flex-shrink-0">
          <Link to={link}
            className="btn btn-sm btn-soft-primary">
            <i className="mdi mdi-eye-outline"></i>
          </Link>
        </div>
      </div>
    </Container>

  return (
    <React.Fragment>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
        message={props.t("Delete-Shipment-Message")}
      />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {props.t("Edit-Shipment")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Name")}</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder={props.t("Shipment-Name-Plc")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name}
                    invalid={
                      validation.touched.name &&
                      validation.errors.name
                    }
                  />
                  {validation.touched.name &&
                    validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{props.t("Amount")}</Label>
                    <Input
                      name="amount"
                      type="number"
                      placeholder={props.t("Amount-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount}
                      invalid={
                        validation.touched.amount &&
                        validation.errors.amount
                      }
                    />
                    {validation.touched.amount &&
                      validation.errors.amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{props.t("Shipment-Date")}</Label>
                    <Input
                      name="shipmentDate"
                      type="date"
                      placeholder={props.t("Shipment-Date-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.shipmentDate}
                      invalid={
                        validation.touched.shipmentDate &&
                        validation.errors.shipmentDate
                      }
                    />
                    {validation.touched.shipmentDate &&
                      validation.errors.shipmentDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.shipmentDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{props.t("Pickup-Location")}</Label>
                    <Input
                      name="pickupId"
                      type="select"
                      placeholder={props.t("Select-Pickup-Location")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pickupId}
                      invalid={
                        validation.touched.pickupId &&
                        validation.errors.pickupId
                      }
                    >
                      {locationOptions ? locationOptions.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                      )) : null}
                    </Input>
                    {validation.touched.pickupId &&
                      validation.errors.pickupId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.pickupId}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{props.t("Delivery-Location")}</Label>
                    <Input
                      name="deliveryId"
                      type="select"
                      placeholder={props.t("Select-Delivery-Location")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.deliveryId}
                      invalid={
                        validation.touched.deliveryId &&
                        validation.errors.deliveryId
                      }
                    >
                      {locationOptions ? locationOptions.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                      )) : null}
                    </Input>
                    {validation.touched.deliveryId &&
                      validation.errors.deliveryId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.deliveryId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{props.t("Vehicle")}</Label>
                    <Input
                      name="vehicleId"
                      type="select"
                      placeholder={props.t("Select-Vehicle")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicleId}
                      invalid={
                        validation.touched.vehicleId &&
                        validation.errors.vehicleId
                      }
                    >
                      <option value="">{props.t("Select-Vehicle")}</option>
                      {vehicleOptions ? vehicleOptions.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                      )) : null
                      }
                    </Input>
                    {validation.touched.vehicleId &&
                      validation.errors.vehicleId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.vehicleId}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{props.t("Priority")}</Label>
                    <Input
                      name="priority"
                      type="select"
                      placeholder={props.t("Select-Priority")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.priority}
                      invalid={
                        validation.touched.priority &&
                        validation.errors.priority
                      }
                    >
                      <option value="">{props.t("Select-Priority")}</option>
                      <option value="low">{props.t("Low")}</option>
                      <option value="medium">{props.t("Medium")}</option>
                      <option value="high">{props.t("High")}</option>
                    </Input>
                    {validation.touched.priority &&
                      validation.errors.priority ? (
                      <FormFeedback type="invalid">
                        {validation.errors.priority}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{props.t("Setup-Duration")}</Label>
                    <Input
                      name="setupDuration"
                      type="number"
                      placeholder={props.t("Setup-Duration-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.setupDuration}
                      invalid={
                        validation.touched.setupDuration &&
                        validation.errors.setupDuration
                      }
                    />
                    {validation.touched.setupDuration &&
                      validation.errors.setupDuration ? (
                      <FormFeedback type="invalid">
                        {validation.errors.setupDuration}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{props.t("Service-Duration")}</Label>
                    <Input
                      name="serviceDuration"
                      type="number"
                      placeholder={props.t("Service-Duration-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.serviceDuration}
                      invalid={
                        validation.touched.serviceDuration &&
                        validation.errors.serviceDuration
                      }
                    />
                    {validation.touched.serviceDuration &&
                      validation.errors.serviceDuration ? (
                      <FormFeedback type="invalid">
                        {validation.errors.serviceDuration}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{props.t("Pickup-Start-Time")}</Label>
                    <Input
                      name="startTime"
                      type="time"
                      placeholder={props.t("Pickup-Start-Time-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startTime}
                      invalid={
                        validation.touched.startTime &&
                        validation.errors.startTime
                      }
                    />
                    {validation.touched.startTime &&
                      validation.errors.startTime ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startTime}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{props.t("Delivery-End-Time")}</Label>
                    <Input
                      name="endTime"
                      type="time"
                      placeholder={props.t("Delivery-End-Time-Plc")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endTime}
                      invalid={
                        validation.touched.endTime &&
                        validation.errors.endTime
                      }
                    />
                    {validation.touched.endTime &&
                      validation.errors.endTime ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endTime}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3 col">
                  <Label className="form-label">{props.t("Notes")}</Label>
                  <Input
                    name="notes"
                    type="text"
                    placeholder={props.t("Notes-Plc")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.notes}
                    invalid={
                      validation.touched.notes &&
                      validation.errors.notes
                    }
                  />
                  {validation.touched.notes &&
                    validation.errors.notes ? (
                    <FormFeedback type="invalid">
                      {validation.errors.notes}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    {props.t("Save")}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Shipments")} breadcrumbItem={props.t("Shipment-Detail")} />
          {isLoading ? <Spinners setLoading={setLoading} />
            :
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody className="p-10 m-0">
                      <MapContainer
                        scrollWheelZoom={false}
                        style={{ height: "40vh", width: "100%" }}
                        center={[0, 0]}
                        zoom={1}
                      >
                        <MapComponent />
                        <TileLayer
                          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                          url={mapTileLayerUrl}
                        />
                        <Marker position={[detail.pickup.latitude, detail.pickup.longitude]} icon={mapIcon}>
                          <Popup> {props.t("Pickup")}: {detail.pickup.name} </Popup>
                        </Marker>
                        <Marker position={[detail.delivery.latitude, detail.delivery.longitude]} icon={mapIcon}>
                          <Popup> {props.t("Delivery")}: {detail.delivery.name} </Popup>
                        </Marker>
                      </MapContainer>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between flex-column"
                        style={{ height: "40vh" }}>
                        <div style={{ overflowX: "auto" }}>
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">{props.t("Name")} :</th>
                                <td>{detail.name}</td>
                                <th scope="row">{props.t("Status")} :</th>
                                <td>
                                  <span className={`badge badge-pill ${shipmentStatuses[detail?.status ?? 400].style}`}>
                                    {shipmentStatuses[detail?.status ?? 400].text}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Amount")} :</th>
                                <td>{detail.amount}</td>
                                <th scope="row">{props.t("Shipment-Date")} :</th>
                                <td>{detail.shipmentDate}</td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Pickup")} :</th>
                                <td>{detail.pickupName}</td>
                                <th scope="row">{props.t("Delivery")} :</th>
                                <td>{detail.deliveryName}</td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Vehicle")} :</th>
                                <td>{detail.vehicleName ?? "-"}</td>
                                <th scope="row">{props.t("Priority")} :</th>
                                <td>{detail.priority}</td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Setup-Duration")} :</th>
                                <td>{detail.setupDuration}</td>
                                <th scope="row">{props.t("Service-Duration")} :</th>
                                <td>{detail.serviceDuration}</td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Pickup-Start-Time")} :</th>
                                <td>{detail.startTime ?? "-"}</td>
                                <th scope="row">{props.t("Delivery-End-Time")} :</th>
                                <td>{detail.endTime ?? "-"}</td>
                              </tr>
                              <tr>
                                <th scope="row">{props.t("Notes")} :</th>
                                <td>{detail.notes ?? "-"}</td>
                                <th /><td />
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <Row className="d-flex justify-content-end m-0">
                          <Col className="mt-3" lg={6} style={{ width: "10rem" }}>
                            <Link className="btn btn-soft-primary align-items-center d-flex justify-content-center"
                              style={{ whiteSpace: "nowrap" }} onClick={() => toggle()}>
                              {props.t("Edit-Shipment")}
                            </Link>
                          </Col>
                          <Col className="mt-3" lg={6} style={{ width: "10rem" }}>
                            <Link className="btn btn-soft-danger align-items-center d-flex justify-content-center"
                              style={{ whiteSpace: "nowrap" }} onClick={() => setDeleteModal(true)}>
                              {props.t("Delete-Shipment")}
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row >
                <Col lg={4}>
                  <Card>
                    <CardBody style={{ height: "30vh" }}>
                      <ShipmentDetailComponentTitle title={props.t("Pickup-Location-Detail")}
                        link={`/location/detail/${detail.pickupId}`} />
                      <div style={{ overflowY: "auto", height: "20vh" }}>
                        <Table className="table-responsive mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">{props.t("Name")}:</th>
                              <td>
                                {detail.pickup.name + " "}
                                <span className={`badge badge-pill ${locationStatuses[detail?.pickup?.status ?? 400].style}`}>
                                  {locationStatuses[detail?.pickup?.status ?? 400].text}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{props.t("Address")}:</th>
                              <td>{setLocationAddress([detail.pickup.address, detail.pickup.city,
                              detail.pickup.state, detail.pickup.zipCode, detail.pickup.country])}</td>
                              <th /><td />
                            </tr>
                          </tbody>
                        </Table>

                      </div>

                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card>
                    <CardBody style={{ height: "30vh" }}>
                      <ShipmentDetailComponentTitle title={props.t("Delivery-Location-Detail")}
                        link={`/location/detail/${detail.deliveryId}`} />
                      <div style={{ overflowY: "auto", height: "20vh" }}>
                        <Table className="table-responsive mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">{props.t("Name")}:</th>
                              <td>
                                {detail.delivery.name + " "}
                                <span className={`badge badge-pill ${locationStatuses[detail?.delivery?.status ?? 400].style}`}>
                                  {locationStatuses[detail?.delivery?.status ?? 400].text}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{props.t("Address")}:</th>
                              <td>{setLocationAddress([detail.delivery.address, detail.delivery.city,
                              detail.delivery.state, detail.delivery.zipCode, detail.delivery.country])}</td>
                              <th /><td />
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card>
                    <CardBody style={{ height: "30vh" }}>
                      <Container style={{ marginBottom: "3vh" }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 card-title flex-grow-1">{props.t("Vehicle-Detail")}</h5>
                          <div className="flex-shrink-0">
                            <Link
                              className="btn btn-sm btn-soft-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                vehicleToggle();
                              }}>
                              <i className={detail.vehicleId ? "mdi mdi-eye-outline" : "mdi mdi-plus-thick"}></i>
                            </Link>

                          </div>
                        </div>
                      </Container>
                      <div style={{ overflowY: "auto", height: "20vh" }}>
                        {
                          detail.vehicleId ?
                            <Table className="table-responsive mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{props.t("Name")}:</th>
                                  <td>
                                    {detail.vehicle.name + " "}
                                    <span className={`badge badge-pill ${vehicleStatuses[detail?.vehicle?.status ?? 400].style}`}>
                                      {vehicleStatuses[detail?.vehicle?.status ?? 400].text}
                                    </span>
                                  </td>
                                  <th /><td />
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Person")}:</th>
                                  <td>{detail.vehicle.person}</td>
                                  <th /><td />
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Type")}:</th>
                                  <td>{detail.vehicle.type}</td>
                                  <th scope="row">{props.t("Capacity")}:</th>
                                  <td>{detail.vehicle.capacity}</td>
                                </tr>
                              </tbody>
                            </Table>
                            :
                            <Container fluid className="text-center d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                              <h5>{props.t("Not-Assigned")}</h5>
                            </Container>
                        }
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          }
          <Modal isOpen={vehicleModal} toggle={vehicleToggle} size={detail.vehicleId ? "md" : "xl"}>
            <ModalHeader toggle={vehicleToggle}>
              {detail.vehicleId ? props.t("Vehicle-Detail") : props.t("Assign-Vehicle")}
            </ModalHeader>
            <ModalBody>
              {
                detail.vehicleId ?
                  <div className="d-flex justify-content-between flex-column">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{props.t("Name")}:</th>
                          <td>
                            {detail.vehicle.name + " "}
                            <span className={`badge badge-pill ${vehicleStatuses[detail?.vehicle?.status ?? 400].style}`}>
                              {vehicleStatuses[detail?.vehicle?.status ?? 400].text}
                            </span>
                          </td>
                          <th /><td />
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Person")}:</th>
                          <td>{detail.vehicle.person}</td>
                          <th /><td />
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Type")}:</th>
                          <td>{detail.vehicle.type}</td>
                          <th scope="row">{props.t("Capacity")}:</th>
                          <td>{detail.vehicle.capacity}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Plate")}:</th>
                          <td>{detail.vehicle.plate ?? "-"}</td>
                          <th scope="row">{props.t("Fixed-Cost")}:</th>
                          <td>{detail.vehicle.fixedCost ?? "0"}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Shift-Start")}:</th>
                          <td>{detail.vehicle.shiftStart ?? "-"}</td>
                          <th scope="row">{props.t("Shift-End")}:</th>
                          <td>{detail.vehicle.shiftEnd ?? "-"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div> : isVehicleLoading ? <Spinners setLoading={setVehicleLoading} /> :
                    vehicles[0] === "Empty" ?
                      <Container fluid className="text-center d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                        <h5>{props.t("No-Vehicles-Found")}</h5>
                      </Container>
                      : <TableContainer
                        columns={vehicleColumns}
                        data={vehicles}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        isPagination={false}
                        iscustomPageSizeOptions={false}
                        isShowingPageLength={true}
                        customPageSize={10}
                        tableClass="table align-middle nowrap mt-2"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination justify-content-end pagination-rounded"
                      />
              }
            </ModalBody>

          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
}

ShipmentDetail.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(ShipmentDetail));