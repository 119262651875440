import {
    GET_OPTIMIZER_WORKLOAD,
    GET_OPTIMIZER_WORKLOAD_FAIL,
    GET_OPTIMIZER_WORKLOAD_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
    workloads: [],
    error: {},
    loading: true
}

const dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OPTIMIZER_WORKLOAD:
            return {
                ...state,
                loading: true,
            }
        case GET_OPTIMIZER_WORKLOAD_SUCCESS:
            // merge new data to old data
            return { 
                ...state, 
                workloads: state.workloads.concat(action.payload),
                loading: false }
        case GET_OPTIMIZER_WORKLOAD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state;
    }
}

export default dashboard;