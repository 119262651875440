import Select from "react-select";

import React, { useEffect, useState, useRef, useMemo } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  Form,
  FormFeedback
} from "reactstrap";
import * as Yup from "yup";

import {
  getOptimizerPayload as getOptimizerPayloadAction,
  optimizeRoute as optimizeRouteAction,
  uploadOptimizerTemplate as uploadOptimizerTemplateAction,
  resetOptimizerData as resetOptimizerDataAction,
  planOptimizerRoute as planOptimizerRouteAction,
} from "store/optimizer/actions";
import { donwloadOptimizerResult } from "helpers/backend_helper";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import SimpleBar from "simplebar-react"

import { toast } from "react-toastify";
import classnames from "classnames"

// Map
import { TileLayer, Marker, Popup, MapContainer, useMap, Polyline } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

import { Info, CollapseButton, MapColorPalette, DepotColor, CustomerColor } from "./component";

import { mapTileLayerUrl } from "constants/map";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { renderToStaticMarkup } from "react-dom/server";

import ErrorImg from "assets/images/error-img.png";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Optimizer = ({t, date, file }) => {
  const dispatch = useDispatch();
  const selectOptimizerState = state => state.optimizer;
  const OptimizerStateProperties = createSelector(
    selectOptimizerState,
    stateProperty => ({
      data: stateProperty.data,
      request: stateProperty.request,
      response: stateProperty.response,
      scenarioIds: stateProperty.scenarioIds,
      isPlanned: stateProperty.isPlanned,
      error: stateProperty.error,
      loading: stateProperty.loading,
    })
  );

  const {
    data,
    request,
    response,
    scenarioIds,
    isPlanned,
    error,
    loading,
  } = useSelector(OptimizerStateProperties);

  const [isLoading, setLoading] = useState(true);
  const [mapBounds, setMapBounds] = useState(null);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [polylines, setPolylines] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [mapInit, setMapInit] = useState(false);

  const shipmentDict = useMemo(() => {
    if (!request || !request.currentDate) {
      return null;
    }

    const dict = {};
    for (let i = 0; i < request.shipments.length; i++) {
      dict[request.shipments[i].id] = request.shipments[i];
    }
    return dict;
  }, [request]);

  const vehicleDict = useMemo(() => {
    if (!request || !request.currentDate) {
      return null;
    }

    const dict = {};
    for (let i = 0; i < request.vehicles.length; i++) {
      dict[request.vehicles[i].id] = request.vehicles[i];
    }
    return dict;
  }, [request]);

  const locationDict = useMemo(() => {
    if (!request || !request.currentDate) {
      return null;
    }

    const dict = {};
    for (let i = 0; i < request.locations.length; i++) {
      dict[request.locations[i].id] = request.locations[i];
    }
    return dict;
  }, [request]);

  useEffect(() => {
    if (date) {
      setLoading(true);
      setPolylines([]);
      setRouteData([]);
      dispatch(resetOptimizerDataAction());
      if (file) {
        dispatch(uploadOptimizerTemplateAction(file));
      } else {
        dispatch(getOptimizerPayloadAction(date));
      }
    }
  }, [date, file]);

  useEffect(() => {
    if (scenarioIds && scenarioIds.length > 0) {
      dispatch(getOptimizerPayloadAction(date));
    }
  }, [scenarioIds, dispatch]);

  useEffect(() => {
    if (response && response.code === 0) {
      const polylines = response.directions.map(direction => direction.points);
      const routeData = response.routes.reduce((acc, vehicleRoute) => {
        const steps = vehicleRoute.steps;
        let hotspots = new Set();
        const selectedSteps = steps.filter((step) => {
          if (step.type === "start") {
            hotspots.add(step.location.name);
            return true;
          } else if (step.type === "end") {
            return false;
          } else if (hotspots.has(step.location.name)) {
            return false;
          } else {
            hotspots.add(step.location.name);
            return true;
          }
        });
        return acc.concat([selectedSteps]);
      }, []);
      setRouteData(routeData);
      setPolylines(polylines);
      setButtonLoading(false);
      setIsChanged(false);
    } else {
      setPolylines([]);
    }
  }, [response]);


  useEffect(() => {
    if (request && request.currentDate) {
      const locations = request.locations;
      const shipments = request.shipments;
      const vehicles = request.vehicles;
      if (!locations || locations.length === 0) {
        return null;
      }
      if (locations[0] !== "Empty") {
        const firstLatLng = L.latLng(locations[0].latitude, locations[0].longitude);
        let bounds = L.latLngBounds(firstLatLng, firstLatLng);

        // Loop through the remaining locations and extend the bounds
        for (let i = 1; i < locations.length; i++) {
          const latLng = L.latLng(locations[i].latitude, locations[i].longitude);
          bounds.extend(latLng);
        }

        setMapBounds(bounds);
      }
      if (shipments && shipments.length > 0 && shipments[0] !== "Empty") {
        for (let i = 0; i < shipments.length; i++) {
          handleCheckboxChange(shipments[i].id, true);
          handleForbiddenVehicleTypeChange(shipments[i].id,
            shipments[i].forbiddenVehicleTypes.map((type) => ({
              value: type, label: capitalizeFirstChar(type)
            })));
          handleForbiddenVehicleIdChange(shipments[i].id,
            shipments[i].forbiddenVehicleIds.map((id) => ({
              value: id, label: vehicleDict[id].name
            })));
        }
      }
      
      if (vehicles && vehicles.length > 0 && vehicles[0] !== "Empty") {
        for (let i = 0; i < vehicles.length; i++) {
          handleCheckboxChange(vehicles[i].id, true);
          handleVehicleStartLocationChange(vehicles[i].id, vehicles[i].startLocation ? {
            value: vehicles[i].startLocation,
            label: locationDict[vehicles[i].startLocation].name
          } : null);
          handleVehicleEndLocationChange(vehicles[i].id, vehicles[i].endLocation ? {
            value: vehicles[i].endLocation,
            label: locationDict[vehicles[i].endLocation].name
          } : null);
        }
      }
      setIsChanged(false);
      setLoading(false);
    }
  }, [request]);

  useEffect(() => {
    if (isPlanned) {
      setIsChanged(false);
    }
  }, [isPlanned]);

  const planRoute = () => {
    if (response && response.code === 0) {
      dispatch(planOptimizerRouteAction(date, response.scenarioId));
    }
  };

  const optimizeRouteManually = () => {
    // Get selected shipments and vehicles
    const shipmentCheckboxes = document.querySelectorAll(".shipment-list:checked");
    const vehicleCheckboxes = document.querySelectorAll(".vehicle-list:checked");
    const lang = localStorage.getItem("I18N_LANGUAGE", "en");

    if (shipmentCheckboxes.length === 0 || vehicleCheckboxes.length === 0) {
      toast.error( lang === "en"
        ? "Please select shipments and vehicles to create a route."
        : "Lütfen bir rota oluşturmak için gönderileri ve araçları seçin.");
      return;
    }

    const selectedShipmentIds = Array.from(shipmentCheckboxes, (checkbox) => checkbox.id);
    const selectedVehicleIds = Array.from(vehicleCheckboxes, (checkbox) => checkbox.id);

    // Filter selected shipments, vehicles, and locations
    const selectedShipments = request.shipments.filter((shipment) => selectedShipmentIds.includes(shipment.id));
    const selectedVehicles = request.vehicles.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

    const selectedLocationIds = new Set(
      selectedShipments.flatMap((shipment) => [shipment.pickup, shipment.delivery])
    );

    for (const ship of selectedShipments) {
      if (ship.vehicle && !selectedVehicleIds.includes(ship.vehicle)) {
        toast.error( lang === "en" 
          ? `Please select vehicle ${vehicleDict[ship.vehicle].name} for shipment ${ship.name}.`
          : `Lütfen ${ship.name} gönderisi için ${vehicleDict[ship.vehicle].name} aracını seçin.`);
        return;
      }
      // set forbidden vehicle type and forbidden vehicle id
      if (!ship.vehicle) {
        ship.forbiddenVehicleTypes = forbiddenVehicleType[ship.id]?.map((item) => item.value) || [];
        ship.forbiddenVehicleIds = forbiddenVehicleId[ship.id]?.map((item) => item.value) || [];
      }
    }

    // Set start and end locations for selected vehicles
    for (const selectedVehicle of selectedVehicles) {
      if (!vehicleStartLocation[selectedVehicle.id]) {
        toast.error( lang === "en"
          ? `Please select a start location for vehicle ${selectedVehicle.name}.`
          : `Lütfen ${selectedVehicle.name} aracı için bir başlangıç ​​konumu seçin.`);
        return;
      }

      selectedVehicle.startLocation = vehicleStartLocation[selectedVehicle.id].value;
      selectedVehicle.endLocation = vehicleEndLocation[selectedVehicle.id]?.value || null;

      // Add startLocation and endLocation to selectedLocationIds
      selectedLocationIds.add(selectedVehicle.startLocation);
      if (selectedVehicle.endLocation !== null) {
        selectedLocationIds.add(selectedVehicle.endLocation);
      }
    }

    const selectedLocations = request.locations.filter((location) => selectedLocationIds.has(location.id));

    // Prepare the data for optimization
    const data = {
      shipments: selectedShipments,
      vehicles: selectedVehicles,
      locations: selectedLocations,
      currentDate: date,
    };
    setButtonLoading(true);
    dispatch(optimizeRouteAction(data));
  };


  const mapIcon = (color, step) => new L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
    html: renderToStaticMarkup(
      <div className='leaflet-pin' style={{ backgroundColor: color }}>
        <span>{step ?? "●"}</span>
      </div>
    )
  });

  const MapComponent = () => {
    function calculateAndSetZoom() {
      if (mapBounds && !mapInit && request.locations[0] !== "Empty") {
        map.fitBounds(mapBounds);
        map.setZoom(map.getZoom() - 1);
        setMapInit(true);
      }
    }

    const map = useMap();
    var button = L.control({ position: 'topright' });

    useEffect(() => {
      button.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
        div.innerHTML = `
        <button style="width: 30px; height: 30px; border-radius: 4px; 
                        border: 2px solid rgba(0,0,0,0.2); background-color: #ffffff; 
                        display: flex; justify-content: center; 
                        align-items: center; font-size: 16px; font-weight: 600; cursor: pointer;">
          <i class="mdi mdi-clipboard-text-outline font-size-18" style="color: #000000;"></i>
        </button>
      `;

        div.onclick = function (e) {
          e.preventDefault();
          e.stopPropagation();
          setDetailModal(true);
        };
        return div;
      }
      button.addTo(map);
      return () => {
        map.removeControl(button);
      }
    }, [map]);
    calculateAndSetZoom();
    return null;
  };

  const [activeTab, setActiveTab] = useState("1")
  const [isChanged, setIsChanged] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [collapseStates, setCollapseStates] = useState({});
  const [vehicleStartLocation, setVehicleStartLocation] = useState({});
  const [vehicleEndLocation, setVehicleEndLocation] = useState({});
  const [forbiddenVehicleType, setForbiddenVehicleType] = useState({});
  const [forbiddenVehicleId, setForbiddenVehicleId] = useState({});
  const [routeStepCollapseStates, setRouteStepCollapseStates] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  const [vehicleConstraintModal, setVehicleConstraintModal] = useState(false);
  const [vehicleConstraint, setVehicleConstraint] = useState({});

  const vehicleValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      capacity: (vehicleConstraint && vehicleConstraint.capacity) || "",
      fixedCost: (vehicleConstraint && vehicleConstraint.fixedCost) || "",
      perKmCost: (vehicleConstraint && vehicleConstraint.perKmCost) || "",
      perHourCost: (vehicleConstraint && vehicleConstraint.perHourCost) || "",
      maxTasks: (vehicleConstraint && vehicleConstraint.maxTasks) || "",
      maxDistance: (vehicleConstraint && vehicleConstraint.maxDistance) || "",
      maxDuration: (vehicleConstraint && vehicleConstraint.maxDuration) || "",
      shiftStart: (vehicleConstraint && vehicleConstraint.shiftStart) || "",
      shiftEnd: (vehicleConstraint && vehicleConstraint.shiftEnd) || "",
    },
    validationSchema: Yup.object({
      capacity: Yup.number().required("Required"),
      fixedCost: Yup.number(),
      perKmCost: Yup.number(),
      perHourCost: Yup.number(),
      maxTasks: Yup.number(),
      maxDistance: Yup.number(),
      maxDuration: Yup.number(),
      shiftStart: Yup.string(),
      shiftEnd: Yup.string(),
    }),
    onSubmit: (values) => {
      const data = {
        capacity: values.capacity,
        fixedCost: values.fixedCost !== "" ? values.fixedCost : 0,
        perKmCost: values.perKmCost !== "" ? values.perKmCost : 1,
        perHourCost: values.perHourCost !== "" ? values.perHourCost : 0,
        maxTasks: values.maxTasks !== "" ? values.maxTasks : null,
        maxDistance: values.maxDistance !== "" ? values.maxDistance : null,
        maxDuration: values.maxDuration !== "" ? values.maxDuration : null,
        shiftStart: values.shiftStart !== "" ? values.shiftStart : null,
        shiftEnd: values.shiftEnd !== "" ? values.shiftEnd : null,
      };
      request.vehicles.forEach((vehicle) => {
        if (vehicle.id === vehicleConstraint.id) {
          vehicle.currentConstraints = data;
          setVehicleConstraint(data);
        }
      });
      vehicleValidation.resetForm();
      vehicleConstraintModalToggle();
    },
  });


  const handleCheckboxChange = (id, value = false) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsChanged(true);
  };

  const handleCollapseChange = id => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }

  const handleVehicleStartLocationChange = (id, value) => {
    setVehicleStartLocation((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsChanged(true);
  }

  const handleVehicleEndLocationChange = (id, value) => {
    setVehicleEndLocation((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsChanged(true);
  }

  const handleForbiddenVehicleTypeChange = (id, value) => {
    setForbiddenVehicleType((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsChanged(true);
  }

  const handleForbiddenVehicleIdChange = (id, value) => {
    setForbiddenVehicleId((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsChanged(true);
  }

  const handleRouteStepCollapseChange = id => {
    setRouteStepCollapseStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setIsChanged(true);
  }

  const detailModalToggle = () => {
    setDetailModal(!detailModal);
  }

  const vehicleConstraintModalToggle = () => {
    setVehicleConstraintModal(!vehicleConstraintModal);
  }

  const downloadExcel = async () => {
    if (response && response.code === 0) {
      await donwloadOptimizerResult(response.scenarioId, date + "_optimizer_result.xlsx");
    }
  }

  const secondToTime = (second, type) => {
    const padZero = (value) => value.toString().padStart(2, '0');

    const hour = Math.floor(second / 3600);
    const minute = Math.floor((second % 3600) / 60);
    const secondRemain = second % 60;

    if (type === 'hour') {
      const timeString = `${padZero(hour)}:${padZero(minute)}:${padZero(secondRemain)}`;
      return timeString;
    }

    if (hour === 0) {
      return `${minute}m ${secondRemain}s`;
    }

    return `${hour}h ${minute}m ${secondRemain}s`;
  };


  function capitalizeFirstChar(inputString) {
    if (inputString.length === 0) {
      return inputString; // Handle empty string
    }

    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  useEffect(() => {
    const vehicleCheckboxes = document.querySelectorAll(".vehicle-list")
    vehicleCheckboxes.forEach(checkbox => {
      checkbox.checked = checkboxStates[checkbox.id] || false;
    });

    const shipmentCheckboxes = document.querySelectorAll(".shipment-list")
    shipmentCheckboxes.forEach(checkbox => {
      checkbox.checked = checkboxStates[checkbox.id] || false;
    });
  }, [checkboxStates, activeTab]);

  return (
    <React.Fragment>
      <div style={{ height: "100%", backgroundColor: "#f8f8fb" }}>
        {isLoading ? <Spinners setLoading={() => { }} /> :
          (request && request.shipments.length > 0 && request.shipments[0] !== "Empty") ?
            <Row className="m-3 d-flex" style={{ height: "100%" }}>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">{t("Vehicles")}</h4>
                    <Nav pills className="bg-light rounded" role="tablist">
                      {
                        [t("All"), t("Car"), t("Truck")].map((item, index) => {
                          return (
                            <NavItem key={(index + 1)}>
                              <NavLink
                                className={classnames({ active: activeTab === (index + 1).toString() })}
                                onClick={() => { setActiveTab((index + 1).toString()) }}
                              >
                                {item}
                              </NavLink>
                            </NavItem>
                          )
                        }
                        )
                      }
                    </Nav>
                    <TabContent activeTab={activeTab} className="mt-4">
                      <SimpleBar style={{ maxHeight: "330px" }}>
                        {
                          request.vehicles.map((item, index) => {
                            if (activeTab === "1" ||
                              (activeTab === "2" && item.fleetVehicleType === t("Car")) ||
                              (activeTab === "3" && item.fleetVehicleType === t("Truck"))) {
                              return (
                                <CardBody className="p-0 pb-3 pt-3 d-flex flex-column border-bottom" key={index}>
                                  <Col className="d-flex justify-content-between align-items-center">
                                    <Info type={item.fleetVehicleType} title={item.name} descp={`${t("Capacity")}: ${item.currentConstraints.capacity}`} />
                                    <div className="d-flex">
                                      <div className="form-check font-size-16 mb-1 me-2 d-flex justify-content-end">
                                        <input className="form-check-input vehicle-list" type="checkbox" id={item.id}
                                          onChange={() => handleCheckboxChange(item.id, !checkboxStates[item.id])}
                                        />
                                        <label className="form-check-label" htmlFor={item.id} />
                                      </div>
                                      <CollapseButton toggle={() => handleCollapseChange(item.id)}
                                        isOpen={collapseStates[item.id] || false} />
                                    </div>
                                  </Col>
                                  <Collapse isOpen={collapseStates[item.id] || false}>
                                    <div className="table-responsive">
                                      <Table className="table-nowrap mb-0 mt-2">
                                        <tbody>
                                          <tr>
                                            <th scope="row">{t("Capacity")} :</th>
                                            <td>{item.person}</td>
                                          </tr>
                                          <tr>
                                            <th scope="row">{t("Plate")} :</th>
                                            <td>{item.plate}</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>

                                    <div className="mt-2">
                                      <Select
                                        value={vehicleStartLocation[item.id] || null}
                                        onChange={(value) => {
                                          handleVehicleStartLocationChange(item.id, value);
                                        }}
                                        options={request ? request.locations.map((location) => ({
                                          value: location.id,
                                          label: location.name,
                                        })) : []}

                                        placeholder={t("Start-Location")}
                                        className="select2-selection"
                                      />
                                    </div>

                                    <div className="mt-2">
                                      <Select
                                        value={vehicleEndLocation[item.id] || null}
                                        onChange={(value) => {
                                          handleVehicleEndLocationChange(item.id, value);
                                        }
                                        }
                                        options={request ? request.locations.map((location) => ({
                                          value: location.id,
                                          label: location.name,
                                        })) : []}
                                        placeholder={t("End-Location")}
                                        className="select2-selection"
                                      />
                                    </div>
                                    <Link onClick={() => {
                                      const constraints = {
                                        id: item.id,
                                        ...item.currentConstraints
                                      }
                                      setVehicleConstraint(constraints);
                                      vehicleConstraintModalToggle();
                                    }}>
                                      <div className="btn-primary btn-link mt-3">{t("Constraints")}</div>
                                    </Link>
                                  </Collapse>
                                </CardBody>
                              )
                            }
                          }
                          )
                        }
                      </SimpleBar>
                    </TabContent>
                  </CardBody>
                  <CardBody className="border-top">
                    <div className="mt-2" />
                    <h4 className="card-title mb-4">{t("Shipments")}</h4>
                    <SimpleBar style={{ maxHeight: "330px" }}>
                      {
                        request.shipments.map((item, index) => {
                          return (
                            <CardBody className="p-0 pb-3 pt-3 d-flex flex-column border-bottom" key={index}>
                              <Col className="d-flex justify-content-between align-items-center">
                                <Info type="box" title={item.name} descp={`${t("Amount")}: ${item.currentConstraints.amount}`} />
                                <div className="d-flex">
                                  <div className="form-check font-size-16 mb-1 me-2 d-flex justify-content-end">
                                    <input className="form-check-input shipment-list" type="checkbox" id={item.id}
                                      onChange={() => handleCheckboxChange(item.id, !checkboxStates[item.id])}
                                    />
                                    <label className="form-check-label" htmlFor={item.id} />
                                  </div>
                                  <CollapseButton toggle={() => handleCollapseChange(item.id)}
                                    isOpen={collapseStates[item.id] || false} />
                                </div>
                              </Col>
                              <Collapse isOpen={collapseStates[item.id] || false}>
                                <div className="table-responsive">
                                  <Table className="table-nowrap mb-0 mt-2">
                                    <tbody>
                                      <tr>
                                        <th scope="row">{t("Pickup")} :</th>
                                        <td>{locationDict[item.pickup].name}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">{t("Delivery")} :</th>
                                        <td>{locationDict[item.delivery].name}</td>
                                      </tr>
                                      {
                                        item.vehicle ?
                                          <tr>
                                            <th scope="row">{t("Vehicle")} :</th>
                                            <td>{vehicleDict[item.vehicle].name}</td>
                                          </tr>
                                          : null
                                      }
                                    </tbody>
                                  </Table>
                                </div>
                                {
                                  !item.vehicle ?
                                    <div className="mt-2">
                                      <Select
                                        value={forbiddenVehicleType[item.id] || null}
                                        onChange={(value) => {
                                          handleForbiddenVehicleTypeChange(item.id, value);
                                        }}
                                        options={[
                                          { value: "car", label: t("Car") },
                                          { value: "truck", label: t("Truck") },
                                        ]}
                                        placeholder={t("Forbidden-Vehicle-Types")}
                                        className="select2-selection"
                                        isMulti={true}
                                      />
                                    </div> : null
                                }
                                {
                                  !item.vehicle ?
                                    <div className="mt-2">
                                      <Select
                                        value={forbiddenVehicleId[item.id] || null}
                                        onChange={(value) => {
                                          handleForbiddenVehicleIdChange(item.id, value);
                                        }}
                                        options={request.vehicles
                                          .map((vehicle) => ({
                                            value: vehicle.id,
                                            label: vehicle.name,
                                          }))}
                                        placeholder={t("Forbidden-Vehicles")}
                                        isMulti={true}
                                        className="select2-selection"
                                      />
                                    </div> : null
                                }
                              </Collapse>
                            </CardBody>
                          )
                        }
                        )
                      }
                      <div className="mt-4">
                        {
                          !isButtonLoading ?
                            <Button
                              color={response ? (isChanged || !isPlanned ? "primary" : "secondary") : "primary"}
                              className="btn btn-block btn-primary"
                              style={{ width: "100%" }}
                              onClick={response ? (isChanged || !isPlanned ? (isChanged ? optimizeRouteManually : planRoute) : null)
                                : optimizeRouteManually}
                            >
                              {response ? (isChanged ? t("Optimize-Route") : t("Plan-Route")) : t("Optimize-Route")}
                            </Button> : <Spinners setLoading={setButtonLoading} />
                        }
                      </div>

                    </SimpleBar>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="d-flex" style={{ height: "95%" }}>
                  <CardBody>
                    <MapContainer
                      scrollWheelZoom={true}
                      style={{ height: "100%", width: "100%" }}
                      center={[0, 0]}
                      zoom={2}
                    >
                      <MapComponent />
                      <TileLayer
                        url={mapTileLayerUrl}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                      />
                      {
                        response && response.code === 0 ?
                          routeData.map((step, index) => {
                            const color = MapColorPalette[index % MapColorPalette.length];
                            return (
                              step.map((item, index) => {
                                if (item.type === "start") {
                                  return (
                                    <Marker key={index} icon={mapIcon(DepotColor, "★")}
                                      position={[item.location.latitude, item.location.longitude]}>
                                      <Popup>{item.location.name}</Popup>
                                    </Marker>
                                  );
                                } else if (item.type === "end") {
                                  return null;
                                }
                                return (
                                  <Marker key={index} icon={mapIcon(color, index)}
                                    position={[item.location.latitude, item.location.longitude]}>
                                    <Popup>{item.location.name}</Popup>
                                  </Marker>
                                )
                              })
                            )
                          }) :
                          request.locations.map((item) => (
                            <Marker key={item.id} position={[item.latitude, item.longitude]} icon={mapIcon(CustomerColor)}>
                              <Popup>{item.name} </Popup>
                            </Marker>
                          ))
                      }
                      {polylines &&
                        polylines.map((polyline, index) => (
                          <Polyline key={index} positions={polyline} weight={5} color={MapColorPalette[index % MapColorPalette.length]} />
                        ))
                      }
                    </MapContainer>
                  </CardBody>
                </Card>
              </Col>
            </Row> :
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
              <h3 className="text-center">{t("No-Active-Shipments")}</h3>
              <h3 className="text-center">{t("No-Active-Shipment-Description")}</h3>
              <img src={ErrorImg} alt="" style={{ width: "25%", height: "25%" }} />
            </div>
        }
      </div>
      {
        response && response.code === 0 &&
        <Modal isOpen={detailModal} toggle={detailModalToggle} size="xl">
          <ModalHeader toggle={detailModalToggle}>{t("Route-Detail")}</ModalHeader>
          <ModalBody>
            <CardBody>
              <CardTitle className="mb-4">
                <div className="d-flex justify-content-between">
                  {t("Summary")}
                  <Link className="btn-primary btn-link" to="#" onClick={downloadExcel}>
                    <p style={{ fontWeight: "normal", fontSize: "14px" }}>{t("Export-Excel")}</p>
                  </Link>
                </div>
              </CardTitle>
              <div className="d-flex justify-content-between flex-column mb-4">
                <div style={{ overflowX: "auto" }}>
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">{t("Total-Cost")} :</th>
                        <td>{response.summary.cost}₺</td>
                        <th scope="row">{t("Total-Distance")} :</th>
                        <td>{(response.summary.distance / 1000).toFixed(1)}km</td>
                      </tr>
                      <tr>
                        <th scope="row">{t("Total-Amount")} :</th>
                        <td>{response.summary.amount[0]}</td>
                        <th scope="row">{t("Total-Vehicles")} :</th>
                        <td>{response.summary.routes}</td>
                      </tr>
                      <tr>
                        <th scope="row">{t("Total-Duration")} :</th>
                        <td>{secondToTime(response.summary.duration)}</td>
                        <th scope="row">{t("Total-Service-Duration")} :</th>
                        <td>{secondToTime(response.summary.service)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
            {
              response.summary.unassigned ?
                <CardBody>
                  <CardTitle className="mb-4">{`${t("Unassigned-Shipments")} (${response.summary.unassigned / 2})`}</CardTitle>
                  <div className="d-flex justify-content-between flex-column mb-4">
                    <div style={{ overflowX: "auto" }}>
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          {response.unassigned.reduce((rows, item, index) => {
                            if (index % 2 === 0) {
                              const pickup = response.unassigned[index].location;
                              const delivery = response.unassigned[index + 1].location;

                              rows.push(
                                <tr key={index}>
                                  <th scope="row">{t("Shipment")} :</th>
                                  <td>{item.name}</td>
                                  <th scope="row">{t("Pickup")} :</th>
                                  <td>{pickup.name}</td>
                                  <th scope="row">{t("Delivery")} :</th>
                                  <td>{delivery.name}</td>
                                  <th scope="row">{t("Amount")} :</th>
                                  <td>{shipmentDict[item.id].currentConstraints.amount}</td>
                                </tr>
                              );
                            }
                            return rows;
                          }, [])}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody> : null
            }
            <CardBody>
              <CardTitle className="mb-4">{t("Routes")}</CardTitle>
              <div className="d-flex justify-content-between flex-column mb-4">
                {
                  response.routes.map((item, index) => {

                    return (
                      <CardBody className="p-0 pb-3 d-flex flex-column border-bottom" key={index}>
                        <Col className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Col xl={2}>
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                  <i className={`fas fa-${item.type}`} />
                                </span>
                              </div>
                            </Col>
                            <Col xl={10}>
                              <p className="mb-1 font-size-14"><b>{t("Name")}:</b> {"  " + item.name}</p>
                              <p className="mb-0 font-size-14"><b>{t("Load")}:</b> {"  " + item.amount[0]}</p>
                            </Col>
                            <Col xl={10}>
                              <p className="mb-1 font-size-14"><b>{t("Cost")}:</b> {"  " + item.cost}₺</p>
                              <p className="mb-0 font-size-14"><b>{t("Distance")}:</b>{"  " + (item.distance / 1000).toFixed(1)}km</p>
                            </Col>
                            <Col xl={10}>
                              <p className="mb-1 font-size-14"><b>{t("Travelling-Duration")}:</b>{"  " + secondToTime(item.duration)}</p>
                              <p className="mb-0 font-size-14"><b>{t("Service-Duration")}:</b> {"  " + secondToTime(item.service)}</p>
                            </Col>
                          </div>
                          <CollapseButton toggle={() => handleRouteStepCollapseChange(item.name)} isOpen={routeStepCollapseStates[item.name] || false} />
                        </Col>
                        <Collapse isOpen={routeStepCollapseStates[item.name] || false}>
                          <div className="table-responsive">
                            <h5 className="mb-4 mt-4">{t("Steps")}</h5>
                            <Table className="table-nowrap mb-0 mt-2">
                              <tbody>
                                {
                                  item.steps.map((step, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1} :</th>
                                        <td />
                                        <th scope="row">{t("Type")} : </th>
                                        <td>{capitalizeFirstChar(step.type)}</td>
                                        <th scope="row">{t("Name")} : </th>
                                        <td>{step.name || (capitalizeFirstChar(step.type) + " Vehicle")}</td>
                                        <th scope="row">{t("Location")} : </th>
                                        <td>{step.location.name}</td>
                                        <th scope="row">{t("Distance")} : </th>
                                        <td>{(step.distance / 1000).toFixed(1)}km</td>
                                        <th scope="row">{t("Arrival")} : </th>
                                        <td>{secondToTime(step.arrival, "hour")}</td>
                                        <th scope="row">{t("Load")} : </th>
                                        <td>{step.load[0]}</td>
                                      </tr>
                                    )
                                  }
                                  )
                                }
                              </tbody>
                            </Table>
                          </div>
                        </Collapse>
                      </CardBody>
                    );
                  }
                  )
                }

              </div>
            </CardBody>
          </ModalBody>
        </Modal>
      }
      <Modal isOpen={vehicleConstraintModal} toggle={vehicleConstraintModalToggle}>
        <ModalHeader toggle={vehicleConstraintModalToggle}>{t("Change-Vehicle-Constraints")}</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              vehicleValidation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs="12">
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{t("Capacity")}</Label>
                    <Input
                      name="capacity"
                      type="number"
                      placeholder={t("Capacity-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.capacity}
                      invalid={
                        vehicleValidation.touched.capacity &&
                        vehicleValidation.errors.capacity
                      }
                    />
                    {vehicleValidation.touched.capacity &&
                      vehicleValidation.errors.capacity ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.capacity}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{t("Shift-Start")}</Label>
                    <Input
                      name="shiftStart"
                      type="time"
                      placeholder={t("Shift-Start-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.shiftStart}
                      invalid={
                        vehicleValidation.touched.shiftStart &&
                        vehicleValidation.errors.shiftStart
                      }
                    />
                    {vehicleValidation.touched.shiftStart &&
                      vehicleValidation.errors.shiftStart ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.shiftStart}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{t("Shift-End")}</Label>
                    <Input
                      name="shiftEnd"
                      type="time"
                      placeholder={t("Shift-End-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.shiftEnd}
                      invalid={
                        vehicleValidation.touched.shiftEnd &&
                        vehicleValidation.errors.shiftEnd
                      }
                    />
                    {vehicleValidation.touched.shiftEnd &&
                      vehicleValidation.errors.shiftEnd ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.shiftEnd}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{t("Fixed-Cost")}</Label>
                    <Input
                      name="fixedCost"
                      type="number"
                      placeholder={t("Fixed-Cost-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.fixedCost}
                      invalid={
                        vehicleValidation.touched.fixedCost &&
                        vehicleValidation.errors.fixedCost
                      }
                    />
                    {vehicleValidation.touched.fixedCost &&
                      vehicleValidation.errors.fixedCost ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.fixedCost}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{t("Max-Tasks")}</Label>
                    <Input
                      name="maxTasks"
                      type="number"
                      placeholder="Enter Vehicle Max Tasks"
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.maxTasks}
                      invalid={
                        vehicleValidation.touched.maxTasks &&
                        vehicleValidation.errors.maxTasks
                      }
                    />
                    {vehicleValidation.touched.maxTasks &&
                      vehicleValidation.errors.maxTasks ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.maxTasks}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{t("Per-Km-Cost")}</Label>
                    <Input
                      name="perKmCost"
                      type="number"
                      placeholder={t("Per-Km-Cost-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.perKmCost}
                      invalid={
                        vehicleValidation.touched.perKmCost &&
                        vehicleValidation.errors.perKmCost
                      }
                    />
                    {vehicleValidation.touched.perKmCost &&
                      vehicleValidation.errors.perKmCost ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.perKmCost}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{t("Max-Distance")}</Label>
                    <Input
                      name="maxDistance"
                      type="number"
                      placeholder={t("Max-Distance-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.maxDistance}
                      invalid={
                        vehicleValidation.touched.maxDistance &&
                        vehicleValidation.errors.maxDistance
                      }
                    />
                    {vehicleValidation.touched.maxDistance &&
                      vehicleValidation.errors.maxDistance ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.maxDistance}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-3 col">
                    <Label className="form-label">{t("Per-Hour-Cost")}</Label>
                    <Input
                      name="perHourCost"
                      type="number"
                      placeholder={t("Per-Hour-Cost-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.perHourCost}
                      invalid={
                        vehicleValidation.touched.perHourCost &&
                        vehicleValidation.errors.perHourCost
                      }
                    />
                    {vehicleValidation.touched.perHourCost &&
                      vehicleValidation.errors.perHourCost ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.perHourCost}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="ms-3 mb-3 col">
                    <Label className="form-label">{t("Max-Duration")}</Label>
                    <Input
                      name="maxDuration"
                      type="number"
                      placeholder={t("Max-Duration-Plc")}
                      onChange={vehicleValidation.handleChange}
                      onBlur={vehicleValidation.handleBlur}
                      value={vehicleValidation.values.maxDuration}
                      invalid={
                        vehicleValidation.touched.maxDuration &&
                        vehicleValidation.errors.maxDuration
                      }
                    />
                    {vehicleValidation.touched.maxDuration &&
                      vehicleValidation.errors.maxDuration ? (
                      <FormFeedback type="invalid">
                        {vehicleValidation.errors.maxDuration}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    {t("Save")}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
}

export default Optimizer;