import React from "react"
import { Container, Row, Col } from "reactstrap"

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Footer = props => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Flio.ai.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                {props.t("Designers")}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

Footer.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Footer)
