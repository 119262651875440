import {
  GET_ALL_SHIPMENTS,
  GET_ALL_SHIPMENTS_FAIL,
  GET_ALL_SHIPMENTS_SUCCESS,
  ADD_SHIPMENT,
  ADD_SHIPMENT_FAIL,
  ADD_SHIPMENT_SUCCESS,
} from "./actionTypes";

export const getAllShipments = (skip, limit) => ({
  type: GET_ALL_SHIPMENTS,
  payload: { skip, limit }
})

export const getAllShipmentsSuccess = shipments => ({
  type: GET_ALL_SHIPMENTS_SUCCESS,
  payload: shipments,
})

export const getAllShipmentsFail = error => ({
  type: GET_ALL_SHIPMENTS_FAIL,
  payload: error,
})

export const addShipment = shipment => ({
  type: ADD_SHIPMENT,
  payload: shipment,
})

export const addShipmentSuccess = shipment => ({
  type: ADD_SHIPMENT_SUCCESS,
  payload: shipment,
})

export const addShipmentFail = error => ({
  type: ADD_SHIPMENT_FAIL,
  payload: error,
})