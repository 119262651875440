import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_OPTIMIZER_WORKLOAD,
    GET_OPTIMIZER_WORKLOAD_FAIL,
    GET_OPTIMIZER_WORKLOAD_SUCCESS,
} from "./actionTypes";

//Include Both Helper File with needed methods
import { prepareAuthentications } from "../../helpers/jwt-token-access/accessToken";

import {
    getOptimizerWorkload
} from "../../helpers/backend_helper";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchOptimizerWorkload({ payload: { date, limit } }) {
    try {
        yield call(prepareAuthentications);
        const response = yield call(getOptimizerWorkload, date, limit)
        yield put({ type: GET_OPTIMIZER_WORKLOAD_SUCCESS, payload: response })
    } catch (error) {
        yield put({ type: GET_OPTIMIZER_WORKLOAD_FAIL, payload: error })
        toast.error(error.response.data.message, { autoClose: 2000 });
    }
}

function* DashboardSaga() {
    yield takeEvery(GET_OPTIMIZER_WORKLOAD, fetchOptimizerWorkload);
}

export default DashboardSaga;